import React, { SetStateAction, useEffect, useRef, useState } from "react";
import Loader from "../../../components/common/Loader";
import { ColumnType } from "../../../webcore/client/types";
import "./index.css";

export interface CellProps {
    row: number;
    col: number;
    data: string;
    width: number;
    height: number;
    onChange: Function;
    onSelect: Function;
    // onDrag: Function;
    onTypeChange: Function;
    toggleEdit: Function;
    handleMouseUp: Function;
    isSelected: boolean;
    isTableFocused: boolean;
    type?: ColumnType;
    isHeader?: boolean;
    isInput?: boolean;
    isOutput?: boolean;
    isHighlighted?: boolean;
}

export interface TypeSelectorProps {
    onTypeChange: Function,
    col: number,
    type?: ColumnType,
}

const TypeSelector = (props: TypeSelectorProps) => {
    const { onTypeChange, col, type } = props;

    return (
        <div className="spreadsheet-cell-type-container">
            <select
                className="spreadsheet-cell-type-selector"
                value={type}
                onChange={e => onTypeChange(e.target.value, col)}
            >
                {Object.values(ColumnType).map(colType => (
                    <option key={colType} value={colType}>
                        {colType}
                    </option>
                ))}

            </select>
        </div>
    );
}



const Cell = (props: CellProps) => {
    const {
        row,
        col,
        data,
        width,
        height,
        onSelect,
        // onDrag,
        onChange,
        onTypeChange,
        toggleEdit,
        handleMouseUp,
        type,
        isHeader,
        isInput,
        isOutput,
        isSelected,
        isTableFocused,
        isHighlighted
    } = props;

    const cellRef = useRef<HTMLInputElement>(null);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newVal, setNewVal] = useState<string>(data);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ctrlPressed, setCtrlPressed] = useState(false);

    const handleSelect = (event: React.MouseEvent) => {
        console.log("HI");
        if (event.button !== 0) return; // must be left click
        onSelect(row, col);
    }

    const handleEdit = () => {
        setIsEditing(true);
        toggleEdit(true);
    }

    const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setNewVal(event.target.value);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const droppedContent = event.dataTransfer.getData('text/plain');

        // Update the content of the cell with the dropped text
        handleEdit();
        handleChange({ target: { value: droppedContent } });
    };

    const handleTabPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Tab') event.preventDefault();
    }

    const handleKeyPress = (event: KeyboardEvent) => {
        if (!isSelected) return;

        if (!isEditing) {
            if (ctrlPressed) return;
            if (event.key === 'Meta' || event.key === 'Control') {
                setCtrlPressed(true);
            }

            const badKeys = ['Meta', 'Alt', 'CapsLock', 'Shift', 'Control', 'Escape', 'Backspace', 'Tab', 'ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp']

            if (badKeys.includes(event.key)) {
                return;
            }

            handleEdit();
            if (event.key !== 'Enter') setNewVal("");
        } else {
            switch (event.key) {
                case 'Tab':
                    onSelect(row, col + 1);
                    break;
                case 'ArrowDown':
                case 'Enter':
                    onSelect(row + 1, col);
                    break;
                case 'ArrowUp':
                    onSelect(Math.max(0, row - 1), col);
                    break;
                default:
                    return;
            }
            event.preventDefault();
            handleMouseUp();
            if (cellRef.current) {
                cellRef.current.blur();
            }
        }
    }

    const flush = () => {
        if (newVal !== data) {
            setIsLoading(true);
            onChange(newVal, row, col, (data: string) => {
                setNewVal(data);
                setIsLoading(false);
            });
        }
        setIsEditing(false);
        toggleEdit(false);
    }

    useEffect(() => {
        setNewVal(data);
    }, [data]);

    useEffect(() => {
        if (isEditing) {
            onSelect(row, col);
            if (cellRef.current) {
                cellRef.current.focus();
            }
            handleMouseUp();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    useEffect(() => {

        const handleKeyUp = (event: KeyboardEvent) => {
            if (event.key === 'Control' || event.key === 'Meta') {
                setCtrlPressed(false);
            }
        }

        if (isSelected && isTableFocused) {
            document.addEventListener('keydown', handleKeyPress);
            document.addEventListener('keyup', handleKeyUp);
        }
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [isEditing, isSelected, isTableFocused, ctrlPressed]);

    let className = `spreadsheet-cell ${(isInput ? "spreadsheet-input-cell" : "")} ${(isOutput ? "spreadsheet-output-cell" : "")} ${isHeader ? "spreadsheet-column-titles" : ""}`
    className = `${className} ${isSelected ? "cell-selected" : ""}`;
    className = `${className} ${isHighlighted ? "cell-highlighted" : ""}`;
    return (
        <div
            className={className}
            key={`cell-${row}-${col}`}
            style={{
                "flexShrink": "0",
                "width": `${width}px`,
                "height": `${height}px`
            }}
            onMouseDown={handleSelect}
            onDoubleClick={handleEdit}
            onMouseUp={() => { handleMouseUp() }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            {isHeader && (
                <TypeSelector
                    type={type}
                    onTypeChange={onTypeChange}
                    col={col}
                />
            )}
            <div
                className={`${isHeader ? "spreadsheet-cell-title-input-wrapper" : "spreadsheet-cell-input-wrapper"}`}
            >
                {isLoading ? (
                    <div className="spreadsheet-cell-loader-container">
                        <div className="spreadsheet-cell-loader-wrapper">
                            <Loader isResizeable={true} />
                        </div>
                    </div>
                ) : (

                    <input
                        disabled={!isEditing}
                        style={{ pointerEvents: !isEditing ? 'none' : 'auto' }}
                        value={newVal}
                        onChange={handleChange}
                        onKeyDown={handleTabPress}
                        onBlur={flush}
                        ref={cellRef}
                        className={"spreadsheet-cell-input"}
                    />
                )}
            </div>


        </div>
    )
}

export default React.memo(Cell);