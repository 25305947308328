export interface Sheet {
    SheetName: string,
    SheetId: string,
    DateCreated: string,
}

export interface Model {
    ModelName: string,
    ModelId: string,
    ModelType: string,
    InputColumns: string[],
    OutputColumn: string,
    ModelMetrics: string,
    Status: string,
    SheetId: string,
    DateCreated: string
}

export interface User {
    FirstName: string;
    LastName: string;
    Organization: string;
    Email: string;
    DateCreated: string;
}

export enum ColumnType {
    TEXT = "text",
    NUMBER = "number",
    CATEGORY = "category",
    DATE = "date"
}

export const getColumnType = (val: string) => {
    const colTypeEnum: ColumnType = (<any>ColumnType)[val];
    if (colTypeEnum !== undefined) {
        return colTypeEnum;
    }
    console.warn("Warning: Bad conversion from type to enum. Defaulting to text.");
    return ColumnType.TEXT;
};

export enum MethodType {
    POST = "POST",
    GET = "GET",
    DELETE = "DELETE",
    PUT = "PUT"
}

export enum ModelType {
    REGRESSION = "Regression",
    CLASSIFICATION = "Classification",
}

export interface TokenInfo {
    token: string,
    cid: string,
    exp: number,
}