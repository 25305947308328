import ModelInfo from "../../components/ModelInfo";
import "./index.css";

const ModelPage = () => {

    return (
        <div id="model-container">

        </div>
    );
};

export default ModelPage;