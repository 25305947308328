import { useState } from 'react';
import { Client } from "../../webcore/client/client";
import Loader from "../common/Loader";
import SpreadButton from "../common/SpreadButton";
import { Metrics } from "../../webcore/metrics/client";
import "./index.css";

const maxFileSize = 100000 * 4; // 100 kbs in bits

export interface FileInputProps {
  handleChange: Function
}

export interface FileUploaderProps {
  onUpload: Function
}

export const FileInput = (props: FileInputProps) => {
  const { handleChange } = props;

  return (
    <div>
      <input id="files"
        type="file"
        onChange={(event) => handleChange(event)}
        style={
          {
            opacity: 0,
            height: "0px",
            width: "0px",
            position: "fixed"
          }
        }

      />
      <label className="custom-file-input" htmlFor="files">Select CSV</label>
    </div>
  );
};

export const FileUploader = (props: FileUploaderProps) => {
  const { onUpload } = props;

  const [file, setFile] = useState(null);
  const [sheetName, setSheetName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fileReader = new FileReader();

  fileReader.onload = () => {
    const data: string = String(fileReader.result);

    if (data.length * 16 > maxFileSize) {
      setIsLoading(false);
      setMessage("File size is too large: max size ~100KBs.");
      return;
    }

    Metrics.logEvent("CreateSheetCSV");

    Client.uploadSheet(data, sheetName)
      .then(response => {
        if (response.ok) {
          setIsLoading(false);
          onUpload();
        } else {
          setIsLoading(false);
          setMessage("Upload failed.");
        }
      });
      
  };

  const handleCreateBlank = () => {
    if (!sheetName) return;
    setIsLoading(true);

    Metrics.logEvent("CreateSheetBlank");

    Client.uploadSheet("", sheetName)
      .then(response => {
        if (response.ok) {
          setIsLoading(false);
          onUpload();
        } else {
          setIsLoading(false);
          setMessage("Upload failed");
        }
      });
  }

  const handleSheetNameChange = (event: any) => {
    setSheetName(event.target.value);
  };

  const handleFileChange = (event: any) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file) return;
    setIsLoading(true);
    fileReader.readAsText(file);
  };

  return (
    <div className="file-uploader-container">
      <h2>Create Sheet</h2>
      <div>Sheet name</div>
      <input className="sheet-name-input" value={sheetName} onChange={handleSheetNameChange} placeholder={"Enter sheet name"} />
      <FileInput handleChange={handleFileChange} />
      {isLoading ? (
        <Loader />
      ) : (
        <div className="file-uploader-submit-button-container">
          <SpreadButton disabled={!sheetName} onClick={() => { handleCreateBlank() }}>
            Create Blank
          </SpreadButton>
          <SpreadButton disabled={!file || !sheetName} onClick={handleSubmit}>Upload Sheet</SpreadButton>
          {message && (<div>{message}</div>)}
        </div>
      )}

    </div>
  );
};

export default FileUploader;
