import { useEffect, useState } from "react";
import "./index.css";
import Trainer from "../../components/Trainer";
import ModelsList from "../../components/ModelsList";
import Loader from "../../components/common/Loader";
import { ColumnType } from "../../webcore/client/types";
import SpreadButton from "../../components/common/SpreadButton";
import SpreadTable from "../components/SpreadTable/SpreadTable";
import { convertAlphaIndexToIndex, isAlpha, isNumeric } from "../Utils/parser_util";
import { DataFrame, TestModel } from "../types";
import Modal from "../../components/common/Modal";

export interface DemoAppProps {
    startDataframe: DataFrame;
    sheetName: string;
    testModels: TestModel[];
    isTrainingDemo?: boolean;
}

const DemoApp = (props: DemoAppProps) => {
    const { startDataframe, sheetName, testModels, isTrainingDemo } = props;

    const [dataframe, setDataframe] = useState<DataFrame>(startDataframe);
    const [inputCols, setInputCols] = useState<boolean[]>([]);
    const [outputCol, setOutputCol] = useState<number>(-1);

    const [isShowPredictors, setIsShowPredictors] = useState<boolean>(false);
    const [isShowTrain, setIsShowTrain] = useState<boolean>(false);
    const [isDemoComplete, setIsDemoComplete] = useState<boolean>(false);

    const [spreadsheetError, setSpreadsheetError] = useState<string | null>(null);

    useEffect(() => {
        if (dataframe) {
            setInputCols(dataframe.data[0] ? dataframe.data[0].map((col, idx) => {
                if (inputCols[idx]) {
                    return inputCols[idx];
                }
                return false;
            }) : []);
        }
    }, [dataframe]);

    const handleDemoComplete = () => {
        if (!isTrainingDemo) return;
        setIsDemoComplete(true);
    }

    const handleInputColsChange = (inputCols: boolean[]) => {
        setInputCols(inputCols);
    }

    const handleOutputColChange = (outputCol: number) => {
        setOutputCol(outputCol);
    }

    const toggleModelView = () => {
        setIsShowTrain(!isShowTrain);
    }

    const evalExpression = (data: string, row: number, col: number, onSuccess: Function) => {
        if (data.length === 0 || data[0] !== '=' || data[data.length - 1] !== ')' || testModels.length === 0) {
            onSuccess(data);
            return;
        }
        for (let testModel of testModels) {
            const model = testModel.modelInfo;
            const formula = `=${model.ModelName}(`;
            if (data.length < formula.length + 1 || data.substring(0, formula.length) !== formula) continue;

            const argsExpression = data.substring(formula.length, data.length - 1);
            const rawArgs = argsExpression.split(',');
            if (rawArgs.length !== model.InputColumns.length) {
                onSuccess(data);
                return;
            }

            const args: string[] = [];
            for (let rawArg of rawArgs) {

                let i = 0;

                let colIdxText = "";

                while (i < rawArg.length && isAlpha(rawArg[i])) {
                    colIdxText += rawArg[i];
                    i++;
                }

                let rowIdxText = "";

                while (i < rawArg.length && isNumeric(rawArg[i])) {
                    rowIdxText += rawArg[i];
                    i++;
                }

                if (i < rawArg.length) {
                    onSuccess(data);
                    return;
                }

                const colIdx = convertAlphaIndexToIndex(colIdxText) - 1;
                const rowIdx = Number(rowIdxText);

                // Make sure its not referencing its own cell
                if (colIdx === col && rowIdx === row) {
                    onSuccess(data);
                    return;
                }

                if (dataframe === null || dataframe.data.length < rowIdx || dataframe.data[rowIdx].length < colIdx) {
                    onSuccess(data);
                    return;
                }
                args.push(dataframe.data[rowIdx][colIdx]);
            }


            onSuccess(String(testModel.predictor(...args)));
            return;
        }

        onSuccess(data);
    }

    const onDfChange = (data: string, rowIdx: number, colIdx: number, onDone: Function) => {
        if (!dataframe) return;

        const writeNewDf = (data: string) => {
            const newData: string[][] = dataframe.data.map((row, idx) => {
                const newRow = [...row];
                if (idx === rowIdx) {
                    newRow[colIdx] = data;
                }
                return newRow;
            });

            const newDataFrame: DataFrame = {
                types: [...dataframe.types],
                data: newData
            }

            setDataframe(newDataFrame);
            onDone(data);
        }

        if (data.length > 0 && data[0] === '=') {
            evalExpression(data, rowIdx, colIdx, writeNewDf);
        } else {
            writeNewDf(data);
        }
    }

    const addCells = (numRows: number, numCols: number) => {
        if (!dataframe) return;

        if (numRows === 0 && numCols === 0) return;

        let longest = 0;

        const newTypes: ColumnType[] = [...dataframe.types];
        for (let i = 0; i < numCols; i++) {
            newTypes.push(ColumnType.TEXT);
        }

        const newData: string[][] = dataframe.data.map((row, idx) => {
            const newRow = [...row];
            for (let i = 0; i < numCols; i++) {
                newRow.push("");
            }
            longest = Math.max(newRow.length, longest);
            return newRow;
        });

        while (numRows > 0) {
            newData.push(Array(longest).fill(""));
            numRows--;
        }

        setDataframe({
            types: newTypes,
            data: newData
        });
    }

    const handleColumnTypeChange = (colType: ColumnType, colIdx: number) => {
        if (!dataframe) return;

        const newTypes: ColumnType[] = [...dataframe.types];
        newTypes[colIdx] = colType;

        setDataframe({
            types: newTypes,
            data: [...dataframe.data]
        });
    }

    const handlePredictButtonPress = (e: any) => {
        setIsShowPredictors(!isShowPredictors);
    }

    return (
        <div className="demo-app-container">
            {isDemoComplete && (
                <Modal relativeParent={true}>
                    <div>
                        Congrats! You have trained a model.
                    </div>
                </Modal>
            )}
            <div className="spread-app-container">
                <div className="spreadsheet-header-container">
                    <div className="spreadsheet-header">
                        <div className="spreadsheet-title">
                            {sheetName}
                        </div>
                        <div className="spreadsheet-options">
                            <div className="spreadsheet-options-button file-button">File</div>
                            <div className="spreadsheet-options-button edit-button">Edit</div>
                            <div className="spreadsheet-options-button predict-button" onClick={handlePredictButtonPress}>Predict</div>
                        </div>
                    </div>
                </div>

                <div className="spreadsheet-wrapper">
                    <div className="spreadsheet-container">
                        {(spreadsheetError) ? (
                            <p>{spreadsheetError}</p>
                        ) : (
                            (dataframe === null) ? (
                                <div className="spreadsheet-loader-container">
                                    <Loader />
                                </div>
                            ) : (
                                <SpreadTable
                                    dataframe={dataframe}
                                    inputCols={(isShowPredictors && isShowTrain) ? inputCols : []}
                                    outputCol={(isShowPredictors && isShowTrain) ? outputCol : -1}
                                    onDfChange={onDfChange}
                                    onTypeChange={handleColumnTypeChange}
                                    addCells={addCells}
                                />)
                        )}

                    </div>
                    {isShowPredictors && (
                        <div className="predictors-container">
                            <div className="predictors-wrapper">
                                {isShowTrain ? (
                                    dataframe && (<div>
                                        <Trainer
                                            columns={dataframe.data[0]}
                                            types={dataframe.types}
                                            onInputChange={handleInputColsChange}
                                            onOutputChange={handleOutputColChange}
                                            inputCols={inputCols}
                                            outputCol={outputCol}
                                            sheetId={sheetName}
                                            onClose={() => { setIsShowPredictors(false) }}
                                            onBack={toggleModelView}
                                            isDemo={true}
                                            onDemoComplete={handleDemoComplete}
                                        />
                                    </div>)
                                ) : (
                                    <div>
                                        <ModelsList
                                            models={testModels.map(model => model.modelInfo)}
                                            onClose={() => { setIsShowPredictors(false) }}
                                            onRefresh={() => { }}
                                        />
                                        <div className="models-list-create-button-container">
                                            <SpreadButton
                                                className="models-list-create-button"
                                                onClick={toggleModelView}
                                            >
                                                Create New
                                            </SpreadButton>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>

    );
};

export default DemoApp;