import { useEffect, useState } from 'react';
import Loader from '../Loader';
import SpreadButton from '../SpreadButton';
import sortDown from '../../../assets/icons/sort-down.svg';
import sortDownLight from '../../../assets/icons/sort-down-light.svg';
import sortUp from '../../../assets/icons/sort-up.svg';

import './index.css';

export interface ColumnDefinition {
    label: string;
    id: string;
    sorter?: (a: any, b: any) => number;
}

export interface TableAction {
    label: string;
    onClick: Function;
    hasArgs?: boolean;
    limitToOne?: boolean;
}

export interface TableProps {
    loading?: boolean;
    title: string;
    columns: ColumnDefinition[];
    items: any[];
    allowMultiselect?: boolean;
    actions: TableAction[];
}

const Table = (props: TableProps) => {
    const { loading, title, columns, items, allowMultiselect, actions } = props;

    const [selected, setSelected] = useState<Set<number>>(new Set());
    const [sortIdx, setSortIdx] = useState<number>(0);
    const [isSorting, setIsSorting] = useState<boolean>(false);
    const [isSortingDesc, setIsSortingDesc] = useState<boolean>(false);

    const handleSelect = (idx: number) => {
        if (!allowMultiselect) {
            if (selected.has(idx)) {
                setSelected(new Set());
            } else {
                setSelected(new Set([idx]))
            }
            return;
        }

        const newSelected: Set<number> = new Set();
        for (const item of selected) {
            newSelected.add(item);
        }
        if (!newSelected.has(idx)) {
            newSelected.delete(idx);
        } else {
            newSelected.add(idx);
        }

        setSelected(newSelected);
    }

    const handleSelectAll = () => {
        const newSelected: Set<number> = new Set();
        if (selected.size < items.length) {
            for (let i = 0; i < items.length; i++) {
                newSelected.add(i);
            }
        }
        setSelected(newSelected);
    }

    const handleToggleSort = (idx: number) => {
        if (sortIdx !== idx || !isSorting) {
            setIsSortingDesc(false);
        } else {
            setIsSortingDesc(!isSortingDesc);
        }
        setSortIdx(idx);
        setIsSorting(true);
    }

    const sortByColumn = () => {
        if (!columns[sortIdx].sorter) {
            return items;
        } else {
            const result = [...items];
            result.sort(columns[sortIdx].sorter);
            return isSortingDesc ? result.reverse() : result;
        }
    }

    useEffect(() => {
        setSelected(new Set());
    }, [items])

    return (
        <div className="spread-table-wrapper">
            <div className="spread-table-header">
                <div className="spread-table-title">
                    {title}
                </div>
                <div className="spread-table-actions">
                    {actions.map((action, idx) => {
                        return (
                            <div key={`spread-table-action-${idx}`} className="spread-table-action">
                                <SpreadButton
                                    className="spread-button-no-min-width"
                                    isSharp={true}
                                    disabled={(action.hasArgs && selected.size === 0) || (action.limitToOne && selected.size !== 1)}
                                    onClick={() => {
                                        if (action.limitToOne) {
                                            console.log(Array.from(selected).map(idx => items[idx])[0]);
                                            action.onClick(Array.from(selected).map(idx => items[idx])[0]);
                                        } else {
                                            action.onClick(Array.from(selected).map(idx => items[idx]));
                                        }
                                    }}
                                >
                                    {action.label}
                                </SpreadButton>
                            </div>

                        );
                    })}
                </div>
            </div>

            <table className="spread-table">
                <tbody>
                    <tr className="spread-table-row spread-table-header-row">
                        <td className="spread-table-row-item spread-table-select-item">
                            <input
                                type="checkbox"
                                disabled={!allowMultiselect}
                                checked={selected.size === items.length}
                                onChange={() => { handleSelectAll() }}
                            />
                        </td>
                        {columns.map((col, idx) => (
                            <td
                                key={`col-header-${idx}`}
                                className="spread-table-row-item"
                                onClick={() => { if (col.sorter) handleToggleSort(idx) }}
                            >
                                <div className="spread-table-col-header">
                                    <div>
                                        {col.label}
                                    </div>
                                    {!col.sorter ? null
                                        : (isSorting && sortIdx === idx) ? (
                                            isSortingDesc ? <img className="sort-icon" src={sortDown} alt="sort-down" />
                                                : <img className="sort-icon" src={sortUp} alt="sort-up" />)
                                            : <img className="sort-icon" src={sortDownLight} alt="sort-down" />
                                    }
                                </div>


                            </td>
                        ))}
                    </tr>
                    {loading ? (
                        <></>
                    ) : (
                        (isSorting ? sortByColumn() : items).map((curr, idx) => {
                            const isSelected = selected.has(idx);
                            return (<tr key={`row-${idx}`} className={`spread-table-row ${isSelected ? "spread-table-row-selected" : ""}`}>
                                <td className="spread-table-row-item spread-table-select-item">
                                    <input
                                        type="checkbox"
                                        checked={isSelected}
                                        onChange={() => { handleSelect(idx) }}
                                    />
                                </td>
                                {columns.map((col, colIdx) => {
                                    return (
                                        <td key={`cell-${idx}-${colIdx}`} className="spread-table-row-item">
                                            {!curr.hasOwnProperty(col.id) ? "-" : curr[col.id]}
                                        </td>
                                    );
                                })}
                            </tr>
                            );
                        }))
                    }
                </tbody>


            </table>
            {loading ? (
                <div className="spread-table-loading">
                    <Loader />
                </div>
            ) : (
                items.length === 0 ? (
                    <div className="spread-table-empty">
                        no items
                    </div>
                ) : <></>
            )}
        </div>
    );
};

export default Table;