import { useEffect, useState } from 'react';
import { Client } from '../../webcore/client/client';
import { ColumnType, ModelType } from '../../webcore/client/types';
import './index.css';
import closeIcon from '../../assets/icons/close.svg';
import SpreadButton from '../common/SpreadButton';
import { Metrics } from '../../webcore/metrics/client';


export const getInputCols = (cols: string[], checks: boolean[]) => {
  const inputCols: string[] = [];
  if (checks.length === 0) return inputCols;

  return cols.filter((col, i) => (checks[i] && cols[i] !== ""));
}

export interface TrainerProps {
  columns: string[];
  types: ColumnType[];
  onInputChange: Function;
  onOutputChange: Function;
  inputCols: boolean[];
  outputCol: number;
  sheetId: string;
  onClose: Function;
  onBack: Function;
  isDemo?: boolean;
  onDemoComplete?: Function;
}

const Trainer = (props: TrainerProps) => {
  const {
    columns,
    types,
    onInputChange,
    onOutputChange,
    inputCols,
    outputCol,
    sheetId,
    onClose,
    onBack,
    isDemo,
    onDemoComplete
  } = props;

  const [modelName, setModelName] = useState<string>("");
  const [modelType, setModelType] = useState<ModelType>(ModelType.REGRESSION);
  const [state, setState] = useState({
    isTraining: false,
    message: ""
  });

  const inputs = getInputCols(columns, inputCols);
  const isTrainable = modelName !== "" && inputs.length > 0 && outputCol;

  const isValidType = (idx: number) => {
    if (modelType === ModelType.REGRESSION) {
      if (types[idx] !== ColumnType.NUMBER) return false;
      return true;
    }
    if (modelType === ModelType.CLASSIFICATION) {
      if (types[idx] !== ColumnType.CATEGORY && types[idx] !== ColumnType.NUMBER) return false;
      return true;
    }
    return true;
  }

  const handleCheckboxChange = (idx: number) => {
    const newChecks = [];
    for (let i = 0; i < columns.length; i++) {
      if (inputCols.length === 0) {
        newChecks.push(idx === i ? true : false);
        continue;
      }
      if (i === idx) {
        newChecks.push(!inputCols[i]);
      } else {
        newChecks.push(inputCols[i]);
      }
    }
    onInputChange(newChecks);
  }

  const handleModelNameChange = (newName: string) => {
    setModelName(newName);
  }

  const handleModelTypeChange = (modelType: ModelType) => {
    setModelType(modelType);
  }

  useEffect(() => {
    const newChecks = [...inputCols];
    onInputChange(newChecks.map((col, idx) => {
      return col && isValidType(idx);
    }));
    if (!isValidType(outputCol)) {
      onOutputChange(-1);
    }
  }, [modelType, types]);

  const handleTrain = () => {
    if (!isTrainable) return;
    setState({
      ...state,
      isTraining: true,
      message: ""
    });
  };

  useEffect(() => {
    if (state.isTraining) {
      if (isDemo) {
        if (onDemoComplete === undefined) return;
        setState({
          ...state,
          isTraining: false,
          message: "Training in progress..."
        });
        onDemoComplete();
        return;
      }

      Metrics.logTraining(modelType, inputs.length, 1);
      Client.trainModel(modelName, modelType, inputs, columns[outputCol], sheetId)
        .then(response => {
          if (response.ok) {
            setState({
              ...state,
              isTraining: false,
              message: "Training in progress..."
            });
          } else {
            setState({
              ...state,
              isTraining: false,
              message: "Failure"
            });
          }
        });
    }
  }, [state.isTraining]);

  const messageClassname = `${state.message === "Training in progress..." ? "status-success" : (state.message === "Failure" ? "status-failure" : "")}`;

  return (
    <div>
      <div className="models-list-header">
        <div className="trainer-header-back" onClick={() => { onBack() }}>
          {"<-"}
        </div>
        <div className="models-list-header-title">Create a Predictor</div>
        <div onClick={() => { onClose() }}>
          <img className="models-list-header-icon" src={closeIcon} alt="close" />
        </div>
      </div>
      <hr className="models-list-header-bar" />

      <div>
        <input
          className="model-name-input"
          value={modelName}
          onChange={(e) => {
            e.preventDefault();
            handleModelNameChange(e.target.value)
          }}
          placeholder="Predictor nickname"
        />
      </div>

      <div className="model-type-select-container">
        <select className="model-type-select" value={modelType} onChange={(e) => { handleModelTypeChange(e.target.value as ModelType) }}>
          <option value={ModelType.REGRESSION}>{ModelType.REGRESSION}</option>
          <option value={ModelType.CLASSIFICATION}>{ModelType.CLASSIFICATION}</option>
        </select>
      </div>

      <h4 className="columns-title">Input Columns</h4>

      <div className="input-col-boxes">
        {columns.map((col, idx) => (
          col !== "" ?
            (
              <div key={`input-checks-${idx}`}>
                <input
                  type="checkbox"
                  checked={inputCols.length === 0 ? false : inputCols[idx]}
                  disabled={columns[idx] === columns[outputCol] || !isValidType(idx)}
                  onChange={() => { handleCheckboxChange(idx) }}
                />
                <label>{col}</label>
              </div>
            ) : (null)
        ))}
      </div>

      <h4 className="columns-title">Output Column</h4>
      <div className="input-col-boxes">
        {columns.map((col, idx) => (
          col !== "" ?
            (
              <div key={`input-checks-${idx}`}>
                <input
                  type="checkbox"
                  checked={outputCol === idx}
                  disabled={(inputCols.length === 0 ? false : inputCols[idx]) || !isValidType(idx)}
                  onChange={() => { onOutputChange(outputCol === idx ? -1 : idx) }}
                />
                <label>{col}</label>
              </div>
            ) : (null)))}
      </div>

      <div className="model-train-button-container">
        <SpreadButton
          onClick={handleTrain}
          disabled={!isTrainable}
          loading={state.isTraining}
        >
          Train
        </SpreadButton>
      </div>
      <p className={messageClassname}>{state.message}</p>
    </div>
  );
}

export default Trainer;
