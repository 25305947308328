import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Layout from './components/Layout';
import DocsPage from './Docs';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import ModelPage from './pages/Model';
import SheetPage from './pages/Sheet';
import TestPage from './pages/Test';
import { Client } from './webcore/client/client';
import { Endpoints } from './webcore/endpoints';

export interface PrivateRouteProps {
  component: JSX.Element,
  isAuthed: boolean
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component, isAuthed } = props;

  return (
    <>
    {isAuthed === true
        ? component
        : <Navigate to={"/login"} />}
    </>
    
    
  );
}

const App = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isAuthed, setIsAuthed] = useState<boolean>(Client.isLoggedIn());

  useEffect(() => {
    Endpoints.fetchEndpointConfig()
      .then(() => {
        setLoading(false);
      })
      .catch(e => {
        console.log('Failed to fetch endpoint configuration.', e);
      });
  }, [])

  return (
    <div id="App">
      {!loading && (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout onLogout={() => {setIsAuthed(false)}} />} >
              <Route index element={<PrivateRoute component={<HomePage />} isAuthed={isAuthed} />} />
              <Route path="sheet/:sheetId" element={<PrivateRoute component={<SheetPage />} isAuthed={isAuthed} />} />
              <Route path="model/:modelId" element={<PrivateRoute component={<ModelPage />} isAuthed={isAuthed} />} />
              <Route path="login" element={<LoginPage onAuth={() => {setIsAuthed(true)}} />} />
              <Route path="docs/*" element={<PrivateRoute component={<DocsPage />} isAuthed={isAuthed} />} />
              <Route path="test" element={<PrivateRoute component={<TestPage />} isAuthed={isAuthed} />} />
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;
