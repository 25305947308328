import { Link } from "react-router-dom";

const ModelsPage = () => {
    return (
        <div className="docs-container-no-example">
            <h1>Models</h1>
            <p>
                Something about models here
            </p>
            <h3>Model Types</h3>
            <div>
                <Link to="/docs/models/regression">Regression</Link>
            </div>
            <div>
                <Link to="/docs/models/classification">Classification</Link>
            </div>
            <div>
                <Link to="/docs/models/clustering">Clustering</Link>
            </div>
        </div>

    )
}

export default ModelsPage;