import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Client } from "../../webcore/client/client";
import SpreadButton from "../common/SpreadButton";
import logoLight from '../../assets/logo/logo-light.png';

import './index.css';
import Loader from "../common/Loader";

export enum LoginPageType {
    SIGN_UP = 'sign-up',
    SIGN_IN = 'sign-in',
    CONFIRMATION = 'confirmation'
}

export interface SigningLoginProps {
    onChange: Function;
    onConfirmation: Function;
    onAuth: Function;
}


const SignIn = (props: SigningLoginProps) => {
    const { onChange, onConfirmation, onAuth } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isRedirect, setIsRedirect] = useState<boolean>(false);
    const [errorStatus, setErrorStatus] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const handleEmailChange = (newEmail: string) => {
        setEmail(newEmail);
    };

    const handlePasswordChange = (newPassword: string) => {
        setPassword(newPassword);
    };

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') {
            return;
        }
        handleLogin();
    };

    const handleLogin = () => {
        if (!email || !password) return;

        setIsLoading(true);

        Client.login(
            email,
            password,
            () => {
                setErrorStatus("");
                setIsLoading(false);
                onAuth();
                setIsRedirect(true);
            },
            (err: { name: string, code: string, message: string }) => {
                console.log(err);
                if (err.code === 'UserNotConfirmedException') {
                    onConfirmation(email);
                } else {
                    setErrorStatus(err.message);
                    setIsLoading(false);
                }

            }
        );
    };

    if (isRedirect) {
        return <Navigate to="/" />;
    }

    return (

        <div className="spreadml-login spread-card">
            <div>
                <img className="login-logo" src={logoLight} alt="SpreadML" />
            </div>
            <input
                className="login-input username-input"
                id="email"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
                placeholder="Email"
                onKeyDown={handleEnterPress}
            />
            <input
                className="login-input password-input"
                id="password"
                type="password"
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                placeholder="Password"
                onKeyDown={handleEnterPress}
            />
            <div className="forgot-password-text-wrapper">
                <span className="forgot-password-text">Forgot password?</span>
            </div>
            <div className="login-button-container">
                <SpreadButton
                    className="login-button login-button-top"
                    onClick={handleLogin}
                    loading={isLoading}
                >
                    Sign in
                </SpreadButton>
            </div>

            <hr className="login-button-divider" />

            <div className="login-button-container">
                <div className="login-button login-button-bottom" onClick={() => { onChange(LoginPageType.SIGN_UP) }}>Sign up</div>
            </div>

            <div className="login-error-message">{errorStatus}</div>
        </div>

    );
}

const SignUp = (props: SigningLoginProps) => {
    const { onChange, onConfirmation } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [errorStatus, setErrorStatus] = useState<string | undefined>(undefined);

    const handleFirstNameChange = (newFirst: string) => {
        setFirstName(newFirst);
    };

    const handleLastNameChange = (newLast: string) => {
        setLastName(newLast);
    }

    const handleEmailChange = (newEmail: string) => {
        setEmail(newEmail);
    };

    const handlePasswordChange = (newPassword: string) => {
        setPassword(newPassword);
    };

    const handleOrganizationChange = (newOrg: string) => {
        setOrganization(newOrg);
    }

    const handleEnterPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') {
            return;
        }
        handleSignUp();
    };

    const handleSignUp = () => {
        if (!email || !password || !organization || !firstName || !lastName) return;

        const savedEmail = email;

        setIsLoading(true);

        Client.signUp(
            email,
            password,
            organization,
            firstName,
            lastName,
            () => {
                onConfirmation(savedEmail);
            },
            (err: { name: string, code: string, message: string }) => {
                setErrorStatus(err.message);
                setIsLoading(false);
            }
        );
    };

    return (

        <div className="spreadml-login spread-card">
            <div>
                <img className="login-logo" src={logoLight} alt="SpreadML" />
            </div>
            {/* <div className="sign-up-disabled-message">Sign up is currently disabled.</div> */}
            <input
                className="login-input name-input"
                id="organization"
                value={organization}
                onChange={(e) => handleOrganizationChange(e.target.value)}
                placeholder="Organization"
                onKeyDown={handleEnterPress}
            />
            <input
                className="login-input name-input"
                id="firstName"
                value={firstName}
                onChange={(e) => handleFirstNameChange(e.target.value)}
                placeholder="First Name"
                onKeyDown={handleEnterPress}
            />

            <input
                className="login-input name-input"
                id="lastName"
                value={lastName}
                onChange={(e) => handleLastNameChange(e.target.value)}
                placeholder="Last Name"
                onKeyDown={handleEnterPress}
            />

            <input
                className="login-input username-input"
                id="username"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
                placeholder="Email"
                onKeyDown={handleEnterPress}
            />
            <input
                className="login-input password-input-signup"
                id="password"
                type="password"
                value={password}
                onChange={(e) => handlePasswordChange(e.target.value)}
                placeholder="Password"
                onKeyDown={handleEnterPress}
            />

            <div className="login-button-container">
                <SpreadButton
                    disabled={!email || !password || !organization || !firstName || !lastName}
                    className="login-button login-button-top"
                    onClick={() => { handleSignUp() }}
                    loading={isLoading}
                >
                    Sign up
                </SpreadButton>
            </div>

            <hr className="login-button-divider" />

            <div className="login-button-container">
                <SpreadButton className="login-button login-button-bottom" onClick={() => { onChange(LoginPageType.SIGN_IN) }}>
                    Sign in
                </SpreadButton>
            </div>
            <div className="login-error-message">{errorStatus}</div>
        </div>

    );
}

export interface ConfirmationProps extends SigningLoginProps {
    email: string;
}

export const Confirmation = (props: ConfirmationProps) => {
    const { email, onChange } = props;

    const [code, setCode] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isResending, setIsResending] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const handleCodeChange = (newCode: string) => {
        setCode(newCode);
    }

    const handleVerify = () => {
        setIsLoading(true);
        console.log(code);
        Client.confirmUser(
            email,
            code,
            (result: any) => {
                console.log(result);
                onChange(LoginPageType.SIGN_IN);
            },
            (err: { name: string, code: string, message: string }) => {
                console.log(err);
                setErrorMessage(err.message);
                setIsLoading(false);
                setCode("");
            }
        );
    };

    useEffect(() => {
        if (code.length >= 6) {
            handleVerify();
        }
        // eslint-disable-next-line
    }, [code]);

    const handleResendCode = () => {
        setIsResending(true);
        Client.resendConfirmation(
            email,
            (result: any) => {
                setIsResending(false);
            },
            (err: { name: string, code: string, message: string }) => {
                setIsResending(false);
                setErrorMessage(err.message);
            }
        )
    }

    return (
        <div className="spreadml-login spread-card">
            <div>
                <img className="login-logo" src={logoLight} alt="SpreadML" />
            </div>

            {/* <div className="sign-up-disabled-message">Sign up is currently disabled.</div> */}
            <div className="verification-code-info">Please enter your verification code from your email.</div>
            {isLoading ? (
                <div className="verification-code-loader">
                    <Loader />
                </div>
            ) : (
                <input
                    className="login-input name-input"
                    id="code"
                    value={code}
                    onChange={(e) => handleCodeChange(e.target.value)}
                    placeholder="Code"
                />
            )}

            <SpreadButton onClick={handleResendCode} loading={isResending}>Request new code</SpreadButton>

            <div className="login-error-message">{errorMessage}</div>


            <hr className="login-button-divider" />

            <div className="login-button-container">
                <SpreadButton className="login-button login-button-bottom" onClick={() => {onChange(LoginPageType.SIGN_IN)}}>
                    Sign in
                </SpreadButton>
            </div>

        </div>
    );
}

export interface LoginProps {
    onAuth: Function;
}

const Login = (props: LoginProps) => {
    const { onAuth } = props;
    const [componentType, setComponentType] = useState<LoginPageType>(LoginPageType.SIGN_IN);
    const [email, setEmail] = useState<string>("");

    const handleConfirmation = (email: string) => {
        setEmail(email);
        setComponentType(LoginPageType.CONFIRMATION);
    }

    return (
        <div>
            {componentType === LoginPageType.SIGN_IN ? (
                <SignIn onChange={setComponentType} onAuth={onAuth} onConfirmation={handleConfirmation} />
            ) : (
                componentType === LoginPageType.SIGN_UP ? (
                    <SignUp onChange={setComponentType} onAuth={onAuth} onConfirmation={handleConfirmation} />
                ) : (
                    <Confirmation email={email} onChange={setComponentType} onAuth={() => { }} onConfirmation={() => { }} />
                )
            )}
        </div>
    );
};

export default Login;