import { SetStateAction, useState } from "react";
import { Client } from "../../webcore/client/client";

const TestComponent = () => {
    const [prompt, setPrompt] = useState<string>("");
    const [result, setResult] = useState<string>("");

    const handleChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setPrompt(event.target.value);
    };

    const handleSubmit = () => {
        Client.postGpt(prompt)
            .then(response => (
                response.json()
            ))
            .then(body => {
                setResult(body["Result"]);
            });
    }

    return (
        <div>
            prompt:
            <input value={prompt} onChange={handleChange} />
            <button onClick={() => { handleSubmit() }}>GPT</button>
            {result}
        </div>
    );
};

const NewTest = () => {
    const cols = [1800, 300, 400];
    const rows = [20,20,20,20,20,20,20];

    return (
        <table style={{
            "tableLayout": "fixed",
            width: `${cols.reduce((accumulator: number, currentValue: number) => {
                return accumulator + currentValue;
              }, 0)}px`
        }}>
            <thead>
                <tr>
                    {cols.map(col => (
                        <th style={{ "width": `${col}px` }}>
                            {col}
                        </th>
                    ))}
                </tr>

            </thead>
            <tbody>
                {rows.map(row => (
                    <tr>
                        {cols.map(col => (
                        <td style={{ "width": `${col}px` }}>
                            {col}
                        </td>
                    ))}
                    </tr>
                ))}
                
            </tbody>
        </table>
    )
}


const TestPage = () => {
    return (
        <div>
            {/* <TestComponent /> */}
            <NewTest />
        </div>
    );
};

export default TestPage;