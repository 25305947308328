import { Client } from '../../webcore/client/client';
import logoLight from '../../assets/logo/logo-light.png';
import './index.css';
import { Link } from 'react-router-dom';

type PageRoute = {
    text: string,
    route: string,
}

const pages: PageRoute[] = [
    { text: "home", route: "/" },
    { text: "docs", route: "/docs" },
];

export interface HeaderProps {
    onLogout: Function;
}

const Header = (props: HeaderProps) => {
    const { onLogout } = props;

    const isLogin = window.location.pathname === '/login';

    const isAuthed = Client.isLoggedIn();

    const logOut = () => {
        Client.logout();
        onLogout();
    };

    return (
        <div className="header-container">
            <Link to="/" className="header-cell"><img className="header-logo" src={logoLight} alt="SpreadML" /></Link>
            <div className="header-cell"></div>
            <div className="header-cell"></div>
            {pages.map((page) => (
                <Link key={page.text} to={page.route} className="header-cell">{page.text}</Link>
            ))}
            {!isLogin || isAuthed ? (
                <Link onClick={logOut} to={'/login'} className="header-cell">logout</Link>
            ) : (
                <Link to={'/login'} className="header-cell">login</Link>
            )}
            {/* Add more header cells as needed */}
        </div>









        // <div className="header">
        //     <div className="header-body">
        //         <a href="/">
        //             <div className="header-logo-container">
        //                 <img className="header-logo" src={logoLight} alt="SpreadML" />
        //             </div>

        //         </a>

        //         <div className="header-navbar">
        //             <nav className="header-nav">
        //                 <ul className="nav-list">
        //                     {
        //                         pages.map((page) => (
        //                             <li key={`listItem-${page.text}`}>
        //                                 <span className="nav-item-label">
        //                                     <a href={page.route}>{page.text}</a>
        //                                 </span>
        //                             </li>
        //                         ))
        //                     }
        //                     <li>
        //                         <span className="nav-item-label">
        //                             {!isLogin || isAuthed ? (
        //                                 <a onClick={logOut} href={'/login'}>logout</a>
        //                             ) : (
        //                                 <a href={'/login'}>login</a>
        //                             )}

        //                         </span>
        //                     </li>
        //                 </ul>
        //             </nav>
        //         </div>
        //     </div>
        // </div>
    )
};

export default Header;