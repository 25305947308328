const CreateSheetDocs = () => {

    return (
        <div className="docs-container-no-example">
            <h1>How to Create/Delete Sheets</h1>
            
            <h3>Create</h3>
            <ol>
                <li>When you log in to SpreadML you will be welcomed to the Home Page, on this page you will see the <b>Create</b> button.</li>
                <li>Clicking on this button will open a prompt window.</li>
                <li>You can either create a blank Sheet or upload data from a .csv file. For either, the first step is the same: write a name for your Sheet.</li>
                <li>Now the option to <b>Select File</b> or <b>Create Blank</b> will show up. You can upload a valid .csv file and hit <b>Submit File</b> to get started or click <b>Create Blank</b> to generate a blank sheet.</li>
                <li>Your new Sheet should be available on your Home Page, if not, you can hit the Refresh button.</li>
                <li>You can sort your sheets by Name or Date Created.</li>
            </ol>
            
            <h3>Delete</h3>
            <ol>
                <li>In order to delete a Sheet, you will need to navigate to the Home Page.</li>
                <li>Once here, click on the white square next to your Sheet’s name.</li>
                <li>Once selected, you will see the <b>Delete</b> button light up.</li>
                <li>You can click this button to delete your sheet.</li>
                <li>You may need to click <b>Refresh</b> if you don’t immediately see the sheet deleted.</li>
            </ol>
            
        </div>
    
    );
        

};

export default CreateSheetDocs;