import { useEffect, useState } from "react";
import { Client } from "../../webcore/client/client";
import { Model } from "../../webcore/client/types";
import Loader from "../common/Loader";
import "./index.css";

export interface PredictorProps {
  model: Model
}

export interface PredictorInputProps {
  columnName: string,
  val: string,
  onChange: Function
}

const PredictorInput = (props: PredictorInputProps) => {
  const { columnName, val, onChange } = props;

  return (
    <div>
      <h4>{columnName}</h4>
      <input type="number" onChange={(e) => { onChange(e.target.value) }} value={val} />
    </div>
  );
}

const Predictor = (props: PredictorProps) => {
  const { model } = props;

  const inputs: string[] = model.InputColumns.map(() => (""));

  const [inferenceInputs, setInferenceInputs] = useState<string[]>(inputs);
  const [resultState, setResultState] = useState<any>({
    result: "",
    loading: false
  });

  const handleChange = (idx: number, newVal: string) => {
    const newInputs: string[] = []
    for (let i = 0; i < inputs.length; i++) {
      if (idx === i) {
        newInputs.push(newVal);
      } else {
        newInputs.push(inferenceInputs[i]);
      }
    }
    setInferenceInputs(newInputs);
  };

  const handleSubmit = async () => {
    setResultState({
      ...resultState,
      loading: true
    });
  };

  useEffect(() => {
    if (!resultState.loading) return;
    const data = [inferenceInputs.map((val) => (parseFloat(val)))];
    Client.predict(model.ModelId, data)
      .then(response => response.json())
      .then(body => {
        setResultState({
          ...resultState,
          result: body["Data"],
          loading: false
        });
      });
  }, [resultState.loading])

  return (
    <div className="predictor-parent">
      <h2>Make a Prediction</h2>
      {inferenceInputs.map((val, idx) => (
        <PredictorInput columnName={model.InputColumns[idx]} onChange={(val: string) => handleChange(idx, val)} val={val} />
      ))}
      {!resultState.loading ? (<div>
        <button onClick={handleSubmit}>Predict</button>
        <div>
          <h4>{model.OutputColumn}</h4>
          <p>{resultState.result}</p>
        </div>
      </div>) : (
        <Loader />
      )}
    </div>
  );
};

export default Predictor;