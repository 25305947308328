import { useEffect } from "react";
import HomeApp from "../../components/HomeApp";
import { Metrics } from "../../webcore/metrics/client";
import "./index.css";

const HomePage = () => {

    useEffect(() => {
        Metrics.logEvent("PageHitHome");
    }, []);

    return (
        <div id="home-container">
            <HomeApp />
        </div>
    );
};

export default HomePage;