import ToggleDiv from "../../../components/common/ToggleDiv";
import DemoApp from "../../../SpreadApp/DemoApp";
import { DataFrame, TestModel } from "../../../SpreadApp/types";
import { ColumnType, ModelType } from "../../../webcore/client/types";

const testDataframe: DataFrame = {
    types: [ColumnType.NUMBER, ColumnType.NUMBER, ColumnType.NUMBER],
    data: [
        ["Pages", "Average Rating", "Price"],
        ["200", "2", "5"],
        ["100", "1", "3"],
        ["400", "5", "20"],
        ["300", "4", "20"],
        ["350", "5", "35"],
        ["285", "3", "13"],
        ["500", "2", "25"],
        ["150", "4", "10"],
    ]
}

const testModels: TestModel[] = [
    {
        modelInfo: {
            ModelName: "prices",
            ModelId: "test-regression-1",
            InputColumns: ["Pages", "Average Rating"],
            OutputColumn: "Price",
            Status: "completed",
            ModelType: ModelType.REGRESSION,
            ModelMetrics: "{}",
            SheetId: "Regression Demo",
            DateCreated: new Date().toString()
        },
        predictor: (input1: string, input2: string) => {
            return Number(input1) / 11.5 * (Math.sqrt((Number(input2) / 5)))
        }
    }
]


const RegressionPage = () => {
    return (
        <div>

            <div className="docs-container-with-example">
                <div className="docs-content-with-example">
                    <h1>
                        Regressions
                    </h1>
                    <h2>
                        Overview
                    </h2>
                    <p>
                        Regression is a type of model that is used to predict a numeric value.
                        The goal of a regression model is to find a mathematical relationship between one or more input variables
                        and an output variable.
                    </p>
                    <p>
                        For example, let's say you want to predict the price of a house based on its size, number of bedrooms, and location.
                        You could use a regression model to find the relationship between these attributes and the price of the house,
                        which would allow you to make predictions about the price of a new house based on its attributes.
                    </p>
                    <p>
                        Regression models are used in a wide variety of applications, including finance, economics, engineering, and social sciences.
                        They are especially useful in situations where there is a large amount of data available and a clear relationship between the input and output variables.
                    </p>

                    <ToggleDiv title={"How the algorithm works"}>
                        <p>
                            Linear regression is a type of machine learning algorithm that is used to predict a continuous numeric value.
                            It works by finding the "best-fit" line that represents the relationship between the input variables
                            (also known as features or predictors) and the output variable (also known as the response or target variable).
                        </p>
                        <p>
                            Imagine you have a scatter plot of data points on a graph, where the x-axis represents the input variable and the y-axis represents the output variable.
                            The goal of linear regression is to draw a straight line that passes through the center of the data points,
                            so that the line represents the relationship between the input and output variables.
                        </p>
                        <p>
                            To find the best-fit line, sklearn uses a mathematical technique called "ordinary least squares".
                            This technique calculates the sum of the squared differences between each data point and the line,
                            and then finds the line that minimizes this sum.
                        </p>
                        <p>
                            Once the best-fit line is found, you can use it to make predictions about new data points.
                        </p>
                    </ToggleDiv>
                </div>
                <div className="docs-example"></div>
            </div>
            <div className="docs-container-with-example">
                <div className="docs-content-with-example">
                    <h2>
                        Model Training
                    </h2>
                    <h3>
                        Inputs
                    </h3>
                    <p>
                        Inputs for regression model training must be Numbers.
                    </p>
                    <h3>
                        Outputs
                    </h3>
                    <p>
                        Outputs for regression model training must be Numbers.
                    </p>
                    <h3>
                        How to make your model better
                    </h3>
                    <h4>Less inputs</h4>
                    <p>
                        It maybe beneficial to limit the amount of attributes/inputs you use.
                        Sometimes, using too many attributes can lead to overfitting, where the model performs well on the training data but poorly on new data.
                    </p>
                    <h4>More data</h4>
                    <p>
                        More items per category will usually improve the accuracy of your predictor.
                        With not enough data or too small a range, the model will not be as familiar to what items of the output should look like in those edge cases
                        and will likely be less accurate.
                    </p>
                    <h4>Data cleaning</h4>
                    <p>
                        Remove or correct errors, missing values, outliers, or irrelevant data from your dataset.
                        Data cleaning can improve the accuracy and reliability of your model by reducing noise and bias in the data.
                    </p>
                    <h4>Attribute relevance</h4>
                    <p>
                        Make sure your data doesn't contain attributes/columns that are irrelevant to the output.
                        By including these attributes, you are telling the model to try to incorporate this information in its decision which can lead it astray.
                    </p>
                </div>

                <div className="docs-example">
                    <p>
                        Below, we have set up a spreadsheet of book data.
                        Since we are working with numerical data, we can use regression to predict the columns.
                        Try to create a regression predictor for the book price below.
                    </p>
                    <div>
                        <DemoApp startDataframe={testDataframe} sheetName={"Book Prices Training Demo"} testModels={[]} isTrainingDemo={true} />
                    </div>
                </div>
            </div>
            <div className="docs-container-with-example">
                <div className="docs-content-with-example">
                    <h2>
                        Predictions
                    </h2>
                    <p>
                        Columns used in predictions using regression models should be numbers.
                    </p>
                    <p>
                        The output of the prediction will be numbers also.
                    </p>
                    <p>
                        Using anything other than numbers will result in unintended results.
                    </p>
                    <p>
                        Also, make sure that the order of the inputs in the predictor match the correct order of the inputs.
                        The predicition may work, but will give an incorrect result.
                    </p>

                </div>
                <div className="docs-example">
                    <p>
                        Using the same book data from the training demo and a premade regression predictor,
                        try to predict book prices using our predictor.
                    </p>
                    <div>
                        <DemoApp startDataframe={testDataframe} sheetName={"Book Prices Predictions Demo"} testModels={testModels} isTrainingDemo={false} />
                    </div>
                </div>

            </div>
        </div>

    )
}

export default RegressionPage;