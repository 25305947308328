import { useCallback, useEffect, useRef, useState } from "react";
import Cell from "../../Cell";
import "./index.css";
import { convertIndexToAlphaIndex } from "../../../Utils/parser_util";
import { DataFrame } from "../../../types";
import React from "react";


export interface BorderResizeProps {
    index: number;
    onResize: Function;
    size: number;
    isRow?: boolean;
}

export const BorderResize = React.memo((props: BorderResizeProps) => {
    const { index, onResize, size, isRow } = props;

    const start = useRef<number | null>(null);
    const newSize = useRef<number>(size);
    const [isGrabbing, setIsGrabbing] = useState<boolean>(false);

    function handleMouseMove(event: MouseEvent) {
        if (start.current !== null) {
            const currentPos = isRow ? event.clientY : event.clientX;
            const distance = currentPos - start.current;
            newSize.current = newSize.current! + distance;
            start.current = currentPos;
        }
    }

    function handleMouseUp() {
        newSize.current = Math.max(20, newSize.current!);
        onResize(index, newSize.current);
        setIsGrabbing(false);
        start.current = null;
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
        const elems = document.getElementsByClassName('spreadsheet-table')
        if (elems.length > 0) {
            (elems[0] as HTMLDivElement).style.cursor = 'default';
        }
    }

    function handleMouseDown(event: React.MouseEvent<HTMLDivElement>) {
        event.preventDefault();
        start.current = isRow ? event.clientY : event.clientX;
        setIsGrabbing(true);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        const elems = document.getElementsByClassName('spreadsheet-table')
        if (elems.length > 0) {
            (elems[0] as HTMLDivElement).style.cursor = 'grab';
        }

    }

    const classname = `border-resize ${isRow ? "resize-row" : "resize-col"}`

    return (
        <div
            className={classname}
            onMouseDown={handleMouseDown}
            style={{
                cursor: isGrabbing ? "grab" : isRow ? "row-resize" : "col-resize"
            }}
        >
        </div>
    )
});

export interface SpreadTableProps {
    dataframe: DataFrame;
    inputCols: boolean[];
    outputCol: number;
    onDfChange: Function;
    onTypeChange: Function;
    addCells: Function;
}

const startingColSize = 120;
const startingRowSize = 25;

const SpreadTable = (props: SpreadTableProps) => {
    const {
        dataframe,
        onDfChange,
        onTypeChange,
        addCells,
        inputCols,
        outputCol,
    } = props;

    const [selectedCell, setSelectedCell] = useState({
        rowIdx: -1,
        colIdx: -1
    });
    const [dragEnd, setDragEnd] = useState<{ row: number, col: number } | null>(null);
    const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [rowSizes, setRowSizes] = useState<number[]>(dataframe.data.map((row, idx) => (idx === 0 ? startingRowSize + 20 : startingRowSize)));
    const [colSizes, setColSizes] = useState<number[]>(dataframe.data[0].map(col => startingColSize));
    const [rowSizesRunningSum, setRowSizesRunningSum] = useState<number[]>(dataframe.data.map((row, idx) => (idx === 0 ? 0 : startingRowSize * idx + 20)));
    const [colSizesRunningSum, setColSizesRunningSum] = useState<number[]>(dataframe.data[0].map((col, idx) => startingColSize * idx));
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const containerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLDivElement>(null);

    const selectCell = useCallback((rowIdx: number, colIdx: number) => {
        setSelectedCell({
            rowIdx: rowIdx,
            colIdx: colIdx
        });
        setIsMouseDown(true);
        setDragEnd(null);
    }, []);

    const findCol = (targetSum: number) => {
        let low = 0;
        let high = colSizesRunningSum.length - 1;
        let index = 0;
        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            if (colSizesRunningSum[mid] <= targetSum) {
                index = mid;
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }
        return index;
    };

    const findRow = (targetSum: number) => {
        let low = 0;
        let high = rowSizesRunningSum.length - 1;
        let index = 0;
        while (low <= high) {
            const mid = Math.floor((low + high) / 2);
            if (rowSizesRunningSum[mid] <= targetSum) {
                index = mid;
                low = mid + 1;
            } else {
                high = mid - 1;
            }
        }
        return index;
    };

    const selectDragEnd = useCallback((event: React.MouseEvent) => {
        if (!isMouseDown || !tableRef.current) return;

        const edges = tableRef.current.getBoundingClientRect();
        const rowIdx = findRow(event.clientY - edges.top);
        const colIdx = findCol(event.clientX - edges.left);
        if (isMouseDown && (rowIdx !== selectedCell.rowIdx || colIdx !== selectedCell.colIdx)) {
            setDragEnd({
                row: rowIdx,
                col: colIdx
            });
        }
    }, [isMouseDown, selectedCell, rowSizesRunningSum, colSizesRunningSum, tableRef]);

    const handleCopy = (event: React.ClipboardEvent<HTMLDivElement>) => {
        if (isEditing || !selectedCell || !isFocused) return;

        if (!dragEnd) {
            event.clipboardData.setData('text/plain', dataframe.data[selectedCell.rowIdx][selectedCell.colIdx]);
            event.preventDefault(); // Prevent default copy behavior
            return;
        }

        const top = Math.min(dragEnd.row, selectedCell.rowIdx);
        const bottom = Math.max(dragEnd.row, selectedCell.rowIdx);
        const left = Math.min(dragEnd.col, selectedCell.colIdx);
        const right = Math.max(dragEnd.col, selectedCell.colIdx);

        const cells = [];

        for (let i = top; i <= bottom; i++) {
            if (i !== top) cells.push("\n");
            for (let j = left; j <= right; j++) {
                if (j !== left) cells.push("\t");
                cells.push(dataframe.data[i][j]);
            }
        }

        navigator.clipboard.writeText(cells.join(''));
    }

    const handleBackspace = () => {
        if (isEditing || selectedCell.colIdx < 0 || selectedCell.rowIdx < 0) return;

        if (!dragEnd) {
            onDfChange("", selectedCell.rowIdx, selectedCell.colIdx, () => { });
            return;
        }

        const top = Math.min(dragEnd.row, selectedCell.rowIdx);
        const bottom = Math.max(dragEnd.row, selectedCell.rowIdx);
        const left = Math.min(dragEnd.col, selectedCell.colIdx);
        const right = Math.max(dragEnd.col, selectedCell.colIdx);

        const cells = [];

        for (let i = top; i <= bottom; i++) {
            if (i !== top) cells.push("\n");
            for (let j = left; j <= right; j++) {
                if (j !== left) cells.push("\t");
                cells.push("");
            }
        }

        onDfChange(cells.join(''), top, left, () => { });
    };

    const handleScroll = () => {
        const container = containerRef.current;
        if (!container) return;

        const containerWidth = container.offsetWidth;
        const containerScrollWidth = container.scrollWidth;
        const containerScrollLeft = container.scrollLeft;

        const containerHeight = container.offsetHeight;
        const containerScrollHeight = container.scrollHeight;
        const containerScrollTop = container.scrollTop;

        let newRows = 0;
        let newCols = 0;

        if (containerWidth + containerScrollLeft >= containerScrollWidth - 60) {
            newCols = 10;
        }

        if (containerHeight + containerScrollTop >= containerScrollHeight - 60) {
            newRows = 20;
        }

        if (newRows === 0 && newCols === 0) return;

        addCells(newRows, newCols);
    };

    const isInput = (idx: number) => {
        return inputCols[idx];
    };

    const isOutput = (idx: number) => {
        return outputCol === idx;
    };

    const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        if (isEditing || !isFocused) return;

        event.preventDefault();

        // Access pasted data from event.clipboardData
        const pastedData = event.clipboardData.getData("text");

        onDfChange(pastedData, selectedCell.rowIdx, selectedCell.colIdx, () => { });
    };

    useEffect(() => {
        handleScroll();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataframe]);

    useEffect(() => {
        window.addEventListener('resize', handleScroll);

        return () => window.removeEventListener('resize', handleScroll);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener('dblclick', () => { setIsMouseDown(false) });
        return () => {
            document.removeEventListener('dblclick', () => { setIsMouseDown(false) });
        };
    }, []);

    useEffect(() => {
        document.addEventListener('dblclick', () => { setIsMouseDown(false) });

        // Add event listener for keydown event
        if (isFocused) {
            document.addEventListener('keydown', handleKeyPress);
        }

        // Remove event listener when component unmounts
        return () => {
            document.removeEventListener('dblclick', () => { setIsMouseDown(false) });
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [isEditing, selectedCell, dragEnd, isFocused]);

    const handleKeyPressHelper = (event: KeyboardEvent, rowChange: number, colChange: number) => {
        event.preventDefault();
        setSelectedCell((prev) => {
            const { rowIdx, colIdx } = prev;
            const newRowIdx = Math.max(0, rowIdx + rowChange);
            const newColIdx = Math.max(0, colIdx + colChange); // Update column index

            return { rowIdx: newRowIdx, colIdx: newColIdx };
        });
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        if (!selectedCell || isEditing) return;

        if (event.key === 'Tab' || event.key === 'ArrowRight') {
            handleKeyPressHelper(event, 0, 1);
        } else if (event.key === 'ArrowLeft') {
            handleKeyPressHelper(event, 0, -1);
        } else if (event.key === 'ArrowDown') {
            handleKeyPressHelper(event, 1, 0);
        } else if (event.key === 'ArrowUp') {
            handleKeyPressHelper(event, -1, 0);
        } else if (event.key === 'Backspace') {
            handleBackspace();
        }
    };

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
          if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsFocused(false);
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [containerRef]);

    useEffect(() => {
        document.addEventListener('mouseup', () => { setIsMouseDown(false) });
        return () => {
            document.removeEventListener('mouseup', () => { setIsMouseDown(false) });
        };
    }, []);

    useEffect(() => {

    }, [isEditing, selectedCell, dragEnd]);

    useEffect(() => {
        let newSizes = [...colSizes];
        let newColRunningSum = [...colSizesRunningSum];
        for (let i = newSizes.length; i < dataframe.data[0].length; i++) {
            newSizes.push(startingColSize);
            newColRunningSum.push(newColRunningSum[i - 1] + startingColSize);
        }

        let newRowSizes = [...rowSizes];
        let newRowRunningSum = [...rowSizesRunningSum];
        for (let i = newRowSizes.length; i < dataframe.data.length; i++) {
            newRowSizes.push(startingRowSize);
            newRowRunningSum.push(newRowRunningSum[i - 1] + startingRowSize);
        }

        setColSizes(newSizes)
        setRowSizes(newRowSizes)
        setColSizesRunningSum(newColRunningSum);
        setRowSizesRunningSum(newRowRunningSum);
    }, [dataframe]);

    const isHighlighted = (row: number, col: number) => {
        if (!dragEnd || !selectedCell) return false;

        const top = Math.min(dragEnd.row, selectedCell.rowIdx);
        const bottom = Math.max(dragEnd.row, selectedCell.rowIdx);
        const left = Math.min(dragEnd.col, selectedCell.colIdx);
        const right = Math.max(dragEnd.col, selectedCell.colIdx);
        return dragEnd && row <= bottom && row >= top && col <= right && col >= left;
    };

    const handleColResize = useCallback((idx: number, val: number) => {
        let newSizes = [...colSizes];
        newSizes[idx] = val;

        let newColRunningSum = [...colSizesRunningSum];

        for (let i = Math.max(idx, 1); i < newColRunningSum.length; i++) {
            newColRunningSum[i] = newColRunningSum[i - 1] + newSizes[i - 1];
        }

        setColSizes(newSizes);
        setColSizesRunningSum(newColRunningSum);
    }, [colSizes, colSizesRunningSum]);

    const handleRowResize = useCallback((idx: number, val: number) => {
        let newSizes = [...rowSizes];
        newSizes[idx] = val;

        let newRowRunningSum = [...rowSizesRunningSum];

        for (let i = Math.max(idx, 1); i < newRowRunningSum.length; i++) {
            newRowRunningSum[i] = newRowRunningSum[i - 1] + newSizes[i - 1];
        }

        setRowSizes(newSizes);
        setRowSizesRunningSum(newRowRunningSum);
    }, [rowSizes, rowSizesRunningSum]);

    const handleMouseUp = useCallback((event: React.MouseEvent) => {
        setIsMouseDown(false);
    }, []);

    const handleToggleEdit = useCallback((isEdit: boolean) => {
        setIsEditing(isEdit);
    }, []);

    return (
        <div
            className="spreadsheet"
            ref={containerRef}
            onScroll={handleScroll}
            onCopy={handleCopy}
            onPaste={handlePaste}
            onClick={() => {setIsFocused(true)}}
        >
            <div className="spreadsheet-table">
                <div className="spreadsheet-row-indexes">
                    <div className="spreadsheet-row-index-container"
                        style={{
                            height: "20px"
                        }}
                    ></div>
                    {dataframe.data.map((row, idx) => (
                        <div className="spreadsheet-row-index-container cell-resizeable"
                            style={{
                                height: `${rowSizes[idx]}px`
                            }}
                        >
                            <div className="spreadsheet-row-index">
                                {idx !== 0 && idx}
                            </div>
                            <BorderResize index={idx} isRow={true} size={rowSizes[idx]} onResize={handleRowResize} />
                        </div>

                    ))}
                </div>

                <div
                    className=""

                >
                    <div key={`row-neg-1`} className="spreadsheet-header-row">
                        {dataframe.data[0] && dataframe.data[0].map((item: string, idx: number) => (
                            <div key={`col-idx-${idx}`} className="spreadsheet-cell spreadsheet-cell-col-idx cell-resizeable"
                                style={{
                                    "width": `${colSizes[idx]}px`,
                                    height: "20px"
                                }}
                            >
                                <div>
                                    {convertIndexToAlphaIndex(idx + 1)}

                                </div>
                                <BorderResize index={idx} size={colSizes[idx]} onResize={handleColResize} />

                            </div>
                        ))}
                    </div>


                    <div
                        onMouseDown={() => setIsMouseDown(true)}
                        onMouseMove={selectDragEnd}
                        ref={tableRef}
                    >
                        {dataframe.data.map((row, rowIdx) => {
                            return (

                                <div key={`row-${rowIdx}`} className="spreadsheet-row"
                                    style={{ height: `${rowSizes[rowIdx]}px` }}
                                >
                                    {row.map((cell, colIdx) => (
                                        <Cell
                                            type={dataframe.types[colIdx]}
                                            key={`cell-${colIdx}`}
                                            width={colSizes[colIdx]}
                                            height={rowSizes[rowIdx]}
                                            col={colIdx}
                                            row={rowIdx}
                                            data={cell}
                                            onSelect={selectCell}
                                            onTypeChange={onTypeChange}
                                            toggleEdit={handleToggleEdit}
                                            handleMouseUp={handleMouseUp}
                                            isHeader={rowIdx === 0}
                                            onChange={onDfChange}
                                            isInput={isInput(colIdx)}
                                            isOutput={isOutput(colIdx)}
                                            isSelected={selectedCell.colIdx === colIdx && selectedCell.rowIdx === rowIdx}
                                            isHighlighted={isHighlighted(rowIdx, colIdx)}
                                            isTableFocused={isFocused}
                                        />
                                    )
                                    )}
                                </div>

                            );
                        })}
                    </div>

                </div>

            </div >
        </div>
    );
}

export default React.memo(SpreadTable);