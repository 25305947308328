import { Outlet } from "react-router-dom";
import Header from "../Header";
import "./index.css";

export interface LayoutProps {
    onLogout: Function;
}

const Layout = (props: LayoutProps) => {
    const { onLogout } = props;

    return (
        <>
            

            {/* <div className="background">
                <div id="p1"></div>
                <div id="p2"></div>
            </div> */}

            <Header onLogout={onLogout} />
            <div id="app-container">
                <Outlet />
            </div>

        </>
    )
};

export default Layout;