import { useState } from "react";
import './index.css';

export interface ToggleDivProps {
    title: string;
    children: React.ReactNode;
    isOpen?: boolean;
    isStartOpen?: boolean; // default to closed
}

const ToggleDiv = (props: ToggleDivProps) => {
    const { title, children, isOpen, isStartOpen } = props;

    const [isShowing, setIsShowing] = useState<boolean>(isStartOpen ? isStartOpen : false);

    const showContent = isOpen !== undefined ? isOpen : isShowing;

    return (
        <div className="toggle-div">
            <div className="toggle-div-header" onClick={() => { setIsShowing(!isShowing) }}>
                <div>
                    {title}
                </div>
            </div>
            <div className="toggle-div-content">
                {showContent && (
                    <div className="toggle-div-content-wrapper">
                        {children}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ToggleDiv;