import Loader from "../Loader";
import './index.css';

export interface SpreadButtonProps {
    onClick?: Function;
    disabled?: boolean;
    loading?: boolean;
    isSharp?: boolean;
    className?: string;
    invertColors?: boolean;
    children: React.ReactNode;
}

const SpreadButton = (props: SpreadButtonProps) => {
    const {
        onClick,
        disabled,
        loading,
        isSharp,
        className,
        invertColors,
        children
    } = props;

    const buttonClassName = `spread-button${disabled ? " spread-button-disabled" : ""}${isSharp ? " spread-button-sharp": " spread-button-round"}${invertColors ? " spread-button-light": " spread-button-dark"} ${className}`;

    return (
        <div
            className={buttonClassName}
            onClick={() => {
                if (!disabled && onClick) {
                    onClick();
                }
            }}
        >
            {!loading ? (
                <div className="spread-button-content-container">
                    {children}
                </div>
            ) : (
                <div className="spread-button-loading-container">
                    <Loader isResizeable={true} />
                </div>
            )}
        </div>
    );
}

export default SpreadButton;