import './index.css';

export interface ModalProps {
    children: React.ReactNode;
    onClose?: Function;
    relativeParent?: boolean;
    isDashboard?: boolean;
}

const Modal = (props: ModalProps) => {
    const { onClose, children, relativeParent, isDashboard} = props;

    const className = `modal-wrapper${relativeParent ? " modal-wrapper-parent-scope" : ""}`;
    const contentClassName = `modal-content${isDashboard ? " modal-content-dashboard": ""}`

    return (
        <div className={className}>
            <div className={contentClassName}>
                <div className="modal-close-button-wrapper">
                    {onClose && <div className="modal-close-button" onClick={() => { onClose() }}>X</div>}
                </div>
                {children}
            </div>
        </div>
    );
}

export default Modal;