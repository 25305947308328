import { useEffect } from "react";
import SpreadApp from "../../SpreadApp/SpreadApp";
import { Metrics } from "../../webcore/metrics/client";
import "./index.css";

const SheetPage = () => {

    useEffect(() => {
        Metrics.logEvent("PageHitSpreadsheet");
    }, []);

    return (
        <div id="sheet-container">
            <SpreadApp />
        </div>
    );
};

export default SheetPage;