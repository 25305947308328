import { useState } from "react";
import Modal from "../common/Modal";
import { Model } from "../../webcore/client/types";
import Loader from "../common/Loader";
import './index.css';
import closeIcon from '../../assets/icons/close.svg';
import ModelInfo from "../ModelInfo";

export interface ModelCardProps {
    model: Model;
}

export const ModelCard = (props: ModelCardProps) => {
    const { model } = props;
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const modelClass = `model-item model-status-${model.Status}`;

    let cardText = "";
    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData('text/plain', `=${model.ModelName}(${model.InputColumns.join(",")})`);
    };

    if (model.Status === "in_progress") {
        cardText = "training...";
    } else if (model.Status === "failed") {
        cardText = "failed";
    }

    return (
        <>
            <div
                draggable={model.Status === "completed"}
                className={modelClass}
                onClick={() => setIsShowModal(true)}
                onDragStart={handleDragStart}
            >
                <p>{model.ModelName}</p>
                <p>{cardText}</p>
            </div>
            {isShowModal && (
                <Modal onClose={() => setIsShowModal(false)} isDashboard={true}>
                    <ModelInfo modelId={model.ModelId}></ModelInfo>
                </Modal>
            )}
        </>
    );

}

export interface ModelsListProps {
    models: Model[];
    onClose: Function;
    onRefresh: Function;
}

const ModelsList = (props: ModelsListProps) => {
    const { models, onClose, onRefresh } = props;

    return (
        <div className="modelslist-container">
            <div className="models-list-header">
                <div className="models-list-header-title">Predictors</div>
                <div onClick={() => { onClose() }}>
                    <img className="models-list-header-icon" src={closeIcon} alt="close" />
                </div>
            </div>
            <hr className="models-list-header-bar" />

            <div className="models-list-refresh-button" onClick={() => { onRefresh() }}>Refresh</div>

            {(models === undefined) ? (
                <div className="models-list-loader-container">
                    <Loader />
                </div>
            ) : (
                <div className="models-list-container">
                    {(models.length === 0) ? (
                        <p>No models</p>
                    ) : (
                        models.map((model, idx) => (
                            <ModelCard
                                key={`model-${idx}`}
                                model={model}
                            />
                        )))}
                </div>
            )}

        </div>
    );
}

export default ModelsList;