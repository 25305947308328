const PredictorsDocs = () => {

    return (
        <div className="docs-container-no-example">

            <h1>Predictors</h1>
            <p>Predictors will help you create predictions from your data. Predictors are machine learning models that you can customize to suit your needs. Setting up a Predictor takes no coding at all. The first, and most important, step is deciding what you want the Predictor to do for you.</p>

            <h2>Predictor Types</h2>
            <p>SpreadML currently supports these predictor types.</p>
            <h3>Classification</h3>
            <hr />
            <p>SpreadML allows users to perform classification on their data using machine learning algorithms. This means that users can train the algorithm to categorize their data into different groups based on certain features or characteristics. The algorithm is trained on a set of labeled data, which is data that has already been categorized by humans. The algorithm then uses this labeled data to learn patterns and create a model that can be used to classify new, unlabeled data. When a user inputs new data, the algorithm will analyze its features and use the model to predict which category it belongs to. By using SpreadML's classification feature, users can automate the process of categorizing their data and gain insights that would otherwise be difficult to obtain manually.</p>
            <h3>Regression</h3>
            <hr />
            <p>In addition to classification, SpreadML also offers users the ability to perform regression analysis on their data using machine learning algorithms. </p>
            <p>Regression is a type of supervised learning that enables users to predict a continuous numerical value, such as the price of a product or the temperature of a region.</p>
            <p>To accomplish this, the algorithm is trained on a labeled dataset, where each data point has a known numerical value. The algorithm then identifies patterns in the data and creates a model that can be used to predict the value of new, unlabeled data. When a user inputs new data, the algorithm analyzes its characteristics and uses the model to make a prediction about its numerical value. SpreadML's regression feature automates the process of predicting numerical values, providing users with insights that would be challenging to obtain manually.</p>
            <br />

            <h3>Using Predictors</h3>
            <hr />
            <p>To use Predictors on your Sheets you can use the following steps:</p>
            <ol>
                <li>On your Home Page, open any Sheet</li>
                <li>Click the <b>Predict</b> button on the toolbar at the top of the Sheet. This will toggle the Predictor menu</li>
                <li>Here, you will be able to view Predictors you have made in the past and also make new Predictors</li>
                <li>To make a new Predictor, hit <b>Create New</b>.</li>
                <li>A Predictor set-up menu will appear, here you can begin with naming your Predictor. Note: the name of your Predictor will be important for later steps so please name it something convenient.</li>
                <li>Next, you can select what prediction you want to do from the drop down.</li>
                <li>Check your data to make sure you don’t have missing values in your data and that there are no empty rows and columns</li>
                <li>You can edit the data type of the column. If your column in numerical then you can change the type to ‘number’ by clicking the purple bubble. In that same cell you can alter the title of the column. </li>
                <li>You should see all of your columns named under the <b>Input Columns</b> and <b>Output Columns</b>. You can select which columns you want to be predicted (Output) and which columns will be used to make a prediction (Input).</li>
                <li>Once this is done, hit <b>Train</b> to start training your Predictor.</li>
                <li>You will see a ‘Training in Progress…’ prompt. Hit the Back arrow on the Predictor set-up menu to go back to your list of Predictors.</li>
                <li>Click <b>Refresh</b> to see your new Predictor on the menu</li>
                <li>Clicking the Predictor name will bring up the Predictor Metrics pop-up which can provide you valuable information into how well your Predictor is working.</li>
                <li>To use your Predictor, you can go to an empty shell and type “=” and then your Predictor name followed by and open parentheses. Then you type in the cells that will be your input cells (make sure to type them comma-seperated with no spaces) and then add your closing parentheses.
                    <ul>
                        <li>Example: “=SamplePredictor(A1,B1,C1)”</li>
                    </ul>
                </li>
                <li>Now just click off of the cell and you should see a loading animation and your prediction pop-up!</li>
            </ol>
            <p>Congrats! You now know the basics of SpreadML, the possibilities are endless for what you can do and what insights you can generate.</p>

        </div>

    );


};

export default PredictorsDocs;