import { AuthenticationDetails, CognitoUser, CognitoUserAttribute, CognitoUserPool, CognitoUserSession } from "amazon-cognito-identity-js";
import { Endpoints } from "../endpoints";
import { MetricType } from "../metrics/types";
import { ColumnType, MethodType, ModelType } from "./types";

const poolData = {
    UserPoolId: 'us-west-2_TxEb69gCR',
    ClientId: '4u2fpnoj8gduaqerl4kev3amd4'
};

const userPool = new CognitoUserPool(poolData);

export class Client {

    static isLoggedIn(): boolean {
        const currentUser = userPool.getCurrentUser();

        return !!currentUser;
    }

    /**
     * Returns client cid.
     * Makes sure that ID token isnt expired, else return null.
     * @returns Cid for client
     */
    static getCid(): string | null {
        return localStorage.getItem("cid");
    }

    static setClient(cid: string) {
        localStorage.setItem("cid", cid);
    }

    /**
     * Login to get and set access tokens.
     * @param username User's username (will be JWT signed)
     * @param password User's password (will be JWT signed)
     * @param onSuccess Will be called with the CognitoUserSession object
     * @param onFailure Will be called with an error
     */
    static login(username: string, password: string, onSuccess?: Function, onFailure?: Function) {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        });

        const userData = {
            Username: username,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                const token = result.getIdToken();
                const tokenInfo = token.decodePayload();
                Client.setClient(tokenInfo.sub);
                if (onSuccess) onSuccess(result);
            },
            onFailure: function (err) {
                if (onFailure) onFailure(err);
            },
        });
    }

    static signUp(email: string, password: string, organization: string, firstName: string, lastName: string, onSuccess: Function, onFailure: Function) {
        const attributes = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email,
            }),
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: firstName,
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: lastName
            }),
            new CognitoUserAttribute({
                Name: 'custom:organization',
                Value: organization,
            })
        ];

        userPool.signUp(email, password, attributes, [], (err, result) => {
            if (err) {
                onFailure(err);
            } else {
                onSuccess(result);
            }
        });
    }

    static confirmUser(email: string, code: string, onSuccess: Function, onFailure: Function) {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.confirmRegistration(code, false, (err, result) => {
            if (err) {
                onFailure(err);
            } else {
                onSuccess(result);
            }
        })
    }

    static resendConfirmation(email: string, onSuccess: Function, onFailure: Function) {
        const userData = {
            Username: email,
            Pool: userPool,
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.resendConfirmationCode((err, result) => {
            if (err) {
                onFailure(err);
            } else {
                onSuccess(result);
            }
        })
    }

    static logout() {
        const currentUser: CognitoUser | null = userPool.getCurrentUser();
        if (currentUser) {
            currentUser.signOut();
        }
    }

    static async getUser() {
        return this.createApiRequest('user', MethodType.GET);
    }

    static async getSheet(sheetId: string) {
        return this.createApiRequest(`sheet/${sheetId}`, MethodType.GET);
    }

    static async deleteSheet(sheetId: string) {
        return this.createApiRequest(`sheet/${sheetId}`, MethodType.DELETE);
    }

    static async getSheets() {
        return this.createApiRequest(`sheets`, MethodType.GET);
    }

    static async getSheetdata(sheetId: string) {
        return this.createApiRequest(`sheetdata/${sheetId}`, MethodType.GET);
    }

    static async getModel(modelId: string) {
        return this.createApiRequest(`model/${modelId}`, MethodType.GET);
    }

    static async deleteModel(modelId: string) {
        return this.createApiRequest(`model/${modelId}`, MethodType.DELETE);
    }

    static async getModels() {
        return this.createApiRequest("models", MethodType.GET);
    }

    static async uploadSheet(csvData: string, sheetName: string) {
        return this.createApiRequest("upload", MethodType.POST, {
            "data": csvData,
            "sheetName": sheetName
        });
    }

    static async trainModel(modelName: string, modelType: ModelType, inputCols: string[], outputCol: string, sheetId: string) {
        return this.createApiRequest("train", MethodType.POST, {
            "ModelName": modelName,
            "ModelType": modelType,
            "InputColumns": inputCols,
            "OutputColumn": outputCol,
            "SheetId": sheetId
        });
    }

    static async predict(modelId: string, data: any[]) {
        return this.createApiRequest("predict", MethodType.POST, {
            "Data": data,
            "ModelId": modelId
        });
    }

    static async updateCell(cells: object, sheetId: string) {
        return this.createApiRequest("cell", MethodType.POST, {
            "SheetId": sheetId,
            "Cells": cells
        });
    }

    static async updateColumnType(sheetId: string, colType: ColumnType, colIdx: number) {
        return this.createApiRequest("cell/type", MethodType.POST, {
            "ColumnIndex": colIdx,
            "ColumnType": colType,
            "SheetId": sheetId
        });
    }

    static async postMetric(metricType: MetricType, item: object) {
        let endpoint = "event";
        if (metricType === MetricType.EVENTS) {
            endpoint = "event"
        } else if (metricType === MetricType.PREDICTION) {
            endpoint = "prediction"
        } else if (metricType === MetricType.TRAINING) {
            endpoint = "training"
        } else {
            return;
        }
        this.createApiRequest(endpoint, MethodType.POST, item, true);
    }

    static async getVisualizations(modelId: string) {
        return this.createApiRequest(`visualizations/${modelId}`, MethodType.GET);
    };

    static async postGpt(prompt: string) {
        return this.createApiRequest('gpt', MethodType.POST, {
            'Prompt': prompt
        });
    }

    static async createApiRequest(endpoint: string, method: MethodType, body?: object, isMetrics?: boolean) {
        if (method === MethodType.POST && !body) {
            throw Error("Post request requires a body");
        }

        const currentUser: CognitoUser | null = userPool.getCurrentUser();

        if (currentUser) {
            const getSessionPromise = new Promise<CognitoUserSession>((resolve, reject) => {
                currentUser.getSession((err: Error | null, session: CognitoUserSession) => {
                    if (err) {
                        console.error('Error retrieving Cognito session', err);
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            });

            try {
                const userSession = await getSessionPromise;
                const idToken: string = userSession.getIdToken().getJwtToken();
                // Use the access and ID tokens to authenticate requests to a protected resource


                let config: { [key: string]: any } = {
                    method: method,
                    headers: {
                        "X-Amz-Security-Token": idToken,
                        "x-api-key": "VMp2c6mcBs1ITrwi3qkqI5FB5zG48TDa3rEQejmd"
                    }
                };

                if (body) {
                    config.body = JSON.stringify(body)
                }

                return fetch(`${isMetrics ? Endpoints.metricsEndpoint() : Endpoints.apiEndpoint()}/${endpoint}`, config);
                
            } catch (error) {
                let config: { [key: string]: any } = {
                    method: method,
                    headers: {
                        "X-Amz-Security-Token": "",
                        "x-api-key": "VMp2c6mcBs1ITrwi3qkqI5FB5zG48TDa3rEQejmd"
                    }
                };

                if (body) {
                    config.body = JSON.stringify(body)
                }

                return fetch(`${isMetrics ? Endpoints.metricsEndpoint() : Endpoints.apiEndpoint()}/${endpoint}`, config);
            }
        }
        let config: { [key: string]: any } = {
            method: method,
            headers: {
                "X-Amz-Security-Token": "",
                "x-api-key": "VMp2c6mcBs1ITrwi3qkqI5FB5zG48TDa3rEQejmd"
            }
        };

        if (body) {
            config.body = JSON.stringify(body)
        }

        return fetch(`${isMetrics ? Endpoints.metricsEndpoint() : Endpoints.apiEndpoint()}/${endpoint}`, config);
    }
}