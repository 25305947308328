import { Link } from "react-router-dom";

const GettingStartedPage = () => {
    return (
        <div className="docs-container-no-example">
            <h1>Getting Started</h1>
            <p>
                This getting started guide will introduce you to the basics on how to use SpreadML.
            </p>
            <h3>Contents</h3>
            <div>
                <Link to="/docs/getting-started/create">Create/Delete Sheets</Link>

            </div>
            <div>
                <Link to="/docs/getting-started/sheet">Using Sheets</Link>

            </div>
            <div>
                <Link to="/docs/getting-started/predictors">Using Predictors</Link>

            </div>
        </div>
    );
};

export default GettingStartedPage;