export const convertIndexToAlphaIndex = (index: number): string => {
    let alphaIndex = "";
    let remainder = 0;

    while (index > 0) {
        remainder = (index - 1) % 26;
        alphaIndex = String.fromCharCode(65 + remainder) + alphaIndex;
        index = Math.floor((index - remainder) / 26);
    }

    return alphaIndex;
};

export const convertAlphaIndexToIndex = (alphaIndex: string): number => {
    let index = 0;

    for (let i = 0; i < alphaIndex.length; i++) {
        const charCode = alphaIndex.charCodeAt(i) - 64; // Convert letter to number (A = 1, B = 2, etc.)
        index = index * 26 + charCode;
    }

    return index;
}

export const isAlpha = (char: string): boolean => {
    const pattern = /[A-Za-z]/;
    return pattern.test(char);
};

export const isNumeric = (char: string): boolean => {
    return !isNaN(Number(char));
};