import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Client } from "../../webcore/client/client";
import "./index.css";
import Trainer from "../../components/Trainer";
import ModelsList from "../../components/ModelsList";
import Loader from "../../components/common/Loader";
import { ColumnType, Model } from "../../webcore/client/types";
import SpreadButton from "../../components/common/SpreadButton";
import SpreadTable from "../components/SpreadTable/SpreadTable";
import { Metrics } from "../../webcore/metrics/client";
import { convertAlphaIndexToIndex, isAlpha, isNumeric } from "../Utils/parser_util";
import { DataFrame } from "../types";


class Flusher {
    private sheetId: string;
    private writes: { [key: string]: { [key: string]: string } };
    private dataframe: DataFrame;
    private count: number;
    private max: number;
    private flush: (data: DataFrame) => void;

    constructor(sheetId: string, dataframe: DataFrame, max: number, flush: (data: DataFrame) => void) {
        this.sheetId = sheetId;
        this.count = 0;
        this.max = max;
        this.flush = flush;

        // need deep copy
        this.dataframe = {
            types: [...dataframe.types],
            data: [...dataframe.data.map(data => ([...data]))]
        };

        this.writes = {};
    }

    increment(data: string, row: number, col: number) {
        this.count++;

        while (this.dataframe.data.length < row) {
            this.dataframe.data.push([""]);
        }
        this.dataframe.data.forEach(currRow => {
            while (currRow.length < col) {
                currRow.push("");
            }
        });
        this.dataframe.data[row][col] = data;

        if (!this.writes.hasOwnProperty(row)) {
            // If key already exists, update the value
            this.writes[row] = { [col]: data };
        } else {
            // If key does not exist, add the key-value pair
            this.writes[row][col] = data;
        }

        if (this.count === this.max) {
            Metrics.logEvent("UpdateCell");
            Client.updateCell(this.writes, this.sheetId);
            this.flush(this.dataframe);
        }
    }
}

const SpreadApp = () => {
    const { sheetId } = useParams();

    const [sheetName, setSheetName] = useState<string>("");
    const [dataframe, setDataframe] = useState<DataFrame | null>(null);
    const [inputCols, setInputCols] = useState<boolean[]>([]);
    const [outputCol, setOutputCol] = useState<number>(-1);
    const [modelList, setModelList] = useState<Model[] | undefined>(undefined);

    const [isShowPredictors, setIsShowPredictors] = useState<boolean>(false);
    const [isShowTrain, setIsShowTrain] = useState<boolean>(false);

    const [spreadsheetError, setSpreadsheetError] = useState<string | null>(null);

    const [isFocused, setIsFocused] = useState<boolean>(false);

    useEffect(() => {
        if (sheetId) {
            Client.getSheetdata(sheetId)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        setSpreadsheetError("There was a problem loading your spreadsheet");
                    }
                })
                .then((body) => {
                    setDataframe(({
                        types: body['Types'],
                        data: body['Data']
                    }));
                    setSheetName(body['Sheet']['SheetName']);
                });
        }
    }, [sheetId]);

    useEffect(() => {
        if (dataframe) {
            setInputCols(dataframe.data[0] ? dataframe.data[0].map((col, idx) => {
                if (inputCols[idx]) {
                    return inputCols[idx];
                }
                return false;
            }) : []);
        }
    }, [dataframe]);

    const handleInputColsChange = (inputCols: boolean[]) => {
        setInputCols(inputCols);
    }

    const handleOutputColChange = (outputCol: number) => {
        setOutputCol(outputCol);
    }

    const toggleModelView = () => {
        setIsShowTrain(!isShowTrain);
    }

    const getPredictors = () => {
        Client.getModels()
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return { "Models": [] }
                }
            })
            .then((body) => {
                const models: Model[] = body["Models"];
                setModelList(models);
            });
    }

    useEffect(() => {
        getPredictors();
    }, []);

    const evalExpression = (data: string, row: number, col: number, onSuccess: Function) => {
        if (data.length === 0 || data[0] !== '=' || data[data.length - 1] !== ')' || modelList === undefined) {
            onSuccess(data, row, col);
            return;
        }

        const gptFormula = '=GPT("';

        if (data.length >= gptFormula.length + 2 && data.substring(0, gptFormula.length) === gptFormula && data.substring(data.length - 2, data.length) === '")') {
            const argsExpression = data.substring(gptFormula.length, data.length - 1);
            Client.postGpt(argsExpression)
                .then(response => response.json())
                .then(body => {
                    onSuccess(body['Result'], row, col);
                });
            return;
        }

        for (let model of modelList) {
            const formula = `=${model.ModelName}(`;
            if (data.length < formula.length + 1 || data.substring(0, formula.length) !== formula) continue;

            const argsExpression = data.substring(formula.length, data.length - 1);
            const rawArgs = argsExpression.split(',');
            if (rawArgs.length !== model.InputColumns.length) {
                onSuccess(data, row, col);
                return;
            }

            const args: string[] = [];
            for (let rawArg of rawArgs) {

                let i = 0;

                let colIdxText = "";

                while (i < rawArg.length && isAlpha(rawArg[i])) {
                    colIdxText += rawArg[i];
                    i++;
                }

                let rowIdxText = "";

                while (i < rawArg.length && isNumeric(rawArg[i])) {
                    rowIdxText += rawArg[i];
                    i++;
                }

                if (i < rawArg.length) {
                    onSuccess(data);
                    return;
                }

                const colIdx = convertAlphaIndexToIndex(colIdxText) - 1;
                const rowIdx = Number(rowIdxText);

                // Make sure its not referencing its own cell
                if (colIdx === col && rowIdx === row) {
                    onSuccess(data, row, col);
                    return;
                }

                if (dataframe === null || dataframe.data.length < rowIdx || dataframe.data[rowIdx].length < colIdx) {
                    onSuccess(data, row, col);
                    return;
                }
                args.push(dataframe.data[rowIdx][colIdx]);
            }

            Metrics.logPrediction(sheetId ? sheetId : "", model.ModelId, model.ModelType, model.InputColumns.length)
            Client.predict(model.ModelId, [args.map(arg => parseFloat(arg))])
                .then(response => response.json())
                .then(body => {
                    onSuccess(String(body['Data']), row, col);
                });
            return;
        }

        onSuccess(data, row, col);
    }

    const onDfChange = (data: string, rowIdx: number, colIdx: number, onDone: Function) => {
        if (!dataframe || !sheetId) return;

        let updatedCells = 0;
        const rows = data.split('\n');
        const cells = rows.map((row) => {
            updatedCells++;
            return row.split('\t');
        });

        const flusher = new Flusher(sheetId, dataframe, updatedCells, (newData: DataFrame) => {
            setDataframe(newData);
            onDone(rows[0][0]);
        });

        for (let i = 0; i < cells.length; i++) {
            for (let j = 0; j < cells[i].length; j++) {
                if (data.length > 0 && data[0] === '=') {
                    evalExpression(cells[i][j], rowIdx + i, colIdx + j, (newData: string) => { flusher.increment(newData, rowIdx + i, colIdx + j) });
                } else {
                    flusher.increment(cells[i][j], rowIdx + i, colIdx + j);
                }
            }

        }
    }

    const addCells = (numRows: number, numCols: number) => {
        if (!dataframe || !sheetId) return;

        if (numRows === 0 && numCols === 0) return;

        let longest = 0;

        const newTypes: ColumnType[] = [...dataframe.types];
        for (let i = 0; i < numCols; i++) {
            newTypes.push(ColumnType.TEXT);
        }

        const newData: string[][] = dataframe.data.map((row, idx) => {
            const newRow = [...row];
            for (let i = 0; i < numCols; i++) {
                newRow.push("");
            }
            longest = Math.max(newRow.length, longest);
            return newRow;
        });

        while (numRows > 0) {
            newData.push(Array(longest).fill(""));
            numRows--;
        }

        setDataframe({
            types: newTypes,
            data: newData
        });
    }

    const handleColumnTypeChange = (colType: ColumnType, colIdx: number) => {
        if (!dataframe || !sheetId) return;

        const newTypes: ColumnType[] = [...dataframe.types];
        newTypes[colIdx] = colType;

        Metrics.logEvent("ColumnTypeChange");
        Client.updateColumnType(sheetId, colType, colIdx);

        setDataframe({
            types: newTypes,
            data: [...dataframe.data]
        });
    }

    const handlePredictButtonPress = (e: any) => {
        Metrics.logEvent("PredictMenuClick");
        setIsShowPredictors(!isShowPredictors);
    }

    if (!sheetId) return <Navigate to="/sheets" />;

    return (
        <div className="spread-app-container">
            <div className="spreadsheet-header-container">
                <div className="spreadsheet-header">
                    <div className="spreadsheet-title">
                        {sheetName ? sheetName : <Loader />}
                    </div>
                    <div className="spreadsheet-options">
                        <div className="spreadsheet-options-button file-button">File</div>
                        <div className="spreadsheet-options-button edit-button">Edit</div>
                        <div className="spreadsheet-options-button predict-button" onClick={handlePredictButtonPress}>Predict</div>
                    </div>
                </div>
            </div>

            <div className="spreadsheet-wrapper">
                <div className="spreadsheet-container">
                    {(spreadsheetError) ? (
                        <p>{spreadsheetError}</p>
                    ) : (
                        (dataframe === null) ? (
                            <div className="spreadsheet-loader-container">
                                <Loader />
                            </div>
                        ) : (
                            <SpreadTable
                                dataframe={dataframe}
                                inputCols={(isShowPredictors && isShowTrain) ? inputCols : []}
                                outputCol={(isShowPredictors && isShowTrain) ? outputCol : -1}
                                onDfChange={onDfChange}
                                onTypeChange={handleColumnTypeChange}
                                addCells={addCells}
                            />)
                    )}

                </div>
                {isShowPredictors && (
                    <div className="predictors-container">
                        <div className="predictors-wrapper">
                            {isShowTrain ? (
                                dataframe && (<div>
                                    <Trainer
                                        columns={dataframe.data[0]}
                                        types={dataframe.types}
                                        onInputChange={handleInputColsChange}
                                        onOutputChange={handleOutputColChange}
                                        inputCols={inputCols}
                                        outputCol={outputCol}
                                        sheetId={sheetId}
                                        onClose={() => { setIsShowPredictors(false) }}
                                        onBack={toggleModelView}
                                    />
                                </div>)
                            ) : (
                                <div>
                                    <ModelsList
                                        models={modelList || []}
                                        onClose={() => { setIsShowPredictors(false) }}
                                        onRefresh={getPredictors}
                                    />
                                    <div className="models-list-create-button-container">
                                        <SpreadButton
                                            className="models-list-create-button"
                                            onClick={toggleModelView}
                                        >
                                            Create New
                                        </SpreadButton>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default SpreadApp;