import { Client } from "../client/client";
import { MetricType } from "./types";
import UAParser from 'ua-parser-js';


export class Metrics {
    private static userAgent: UAParser;

    static logEvent(event: string) {
        Client.postMetric(MetricType.EVENTS, {
            ...Metrics.createMetric(),
            "Event": event
        });
    }

    static logPrediction(sheetId: string, modelId: string, modelType: string, numInputs: number) {
        Client.postMetric(MetricType.PREDICTION, {
            ...Metrics.createMetric(),
            "SheetId": sheetId,
            "ModelId": modelId,
            "ModelType": modelType,
            "NumInputs": numInputs
        });
    }

    static logTraining(modelType: string, numInputs: number, numOutputs: number) {
        Client.postMetric(MetricType.TRAINING, {
            ...Metrics.createMetric(),
            "ModelType": modelType,
            "NumInputs": numInputs,
            "NumOutputs": numOutputs
        });
    }

    static createMetric() {
        const ua = this.getUserAgent()

        return {
            "AppName": "SpreadMLApp",
            "Timestamp": new Date().toISOString(),
            "Browser": ua.browser.name,
            "OperatingSystem": ua.os.name,
            "Device": ua.device.model
        }
    }

    static getUserAgent(): UAParser.IResult {
        if (Metrics.userAgent === undefined) {
            Metrics.userAgent = new UAParser(window.navigator.userAgent);
        }

        return Metrics.userAgent.getResult();
    }

}