import './index.css';

const GptPage = () => {
    return (
        <div className="docs-container-no-example">
            <h1>GPT Predictors</h1>
            <p>
                SpreadML allows you to use OpenAi's GPT models for your predictors.

            </p>
            <h3>Predictor Format</h3>
            <p>You can use the GPT predictor as you would use any other predictor.
                Inside of your cell, specify the GPT predictor and insert your prompt in quotation marks.

            </p>
            <p>Here is an example below.</p>
            <div className="gpt-predictor-example-cell">
                <div className="gpt-predictor-example-cell-content">{'=GPT("your prompt here")'}</div>
            </div>
        </div>
    );
};

export default GptPage;