import "./index.css";


const DocsHome = () => {
    return (
        <div className="docs-home-container"> 
            <h1>SpreadML User Guide</h1>
            
            

        </div>
        
    )
}







export default DocsHome;