import { useEffect, useState } from "react";
import { Client } from "../../webcore/client/client";
import { Model } from "../../webcore/client/types";
import Loader from "../common/Loader";
import Predictor from "../Predictor";
import "./index.css";

export interface ModelInfoProps {
	modelId: string;
}

export interface MetricProps {
	metricName: string;
	metricValue: string | number;
	size?: string;
	tooltipText?: string;
}

const MetricBox = (props: MetricProps) => {
	return (
		<div className="metric-box spread-card">
			<div className="metric-label">
				{props.metricName}
				<div className="metric-info-icon">
					<div className="metric-tooltip">?</div>
					<div className="metric-info-tooltip">
						example
						{props.tooltipText}
					</div>
				</div>
			</div>
			<div className="metric-value">{props.metricValue}</div>
		</div>
	);
}

const dateFormatter = (dateString: string) => {
	const date = new Date(dateString);
	const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
		'July', 'August', 'September', 'October', 'November', 'December'];

	const monthIndex = date.getMonth();
	const year = date.getFullYear();
	const day = date.getDate();
	const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

	return `${monthNames[monthIndex]} ${day}, ${year} ${time}`;
}

const jsonParser = (jsonString: string) => {
	const jsonObject = {} as { [key: string]: string };
	if (jsonString && jsonString.length > 0) {
		jsonString
			.slice(1, -1)
			.split(',')
			.forEach((keyValuePair) => {
				const [key, value] = keyValuePair.trim().split(':');
				if (key && value) { // add null check here
					jsonObject[key.slice(1, -1)] = value.replace(/^'|'$/g, '');
				}
			});
	}
	for (const [key, value] of Object.entries(jsonObject)) {
		jsonObject[key] = value.replace(/'/g, '');
	}
	return jsonObject;
}

const renderJson = (json: object) => {
	const objEntries = Object.entries(json);
	return objEntries.map(([key, value]) => (
		<MetricBox
			metricName={key}
			metricValue={Number(value).toLocaleString('en-US', { maximumFractionDigits: 2 })}
			size="small"
		/>
	));
};

const ModelInfo = (props: ModelInfoProps) => {
	const { modelId } = props;
	const [model, setModel] = useState<Model | undefined>(undefined);
	const [imageUrl, setImageUrl] = useState<any | undefined>(undefined);
	const [isLoadingImage, setIsLoadingImage] = useState<boolean>(true);

	useEffect(() => {
		if (model || !modelId) return;
		Client.getModel(modelId)
			.then((response) => response.json())
			.then((body) => {
				const model: Model = body["Model"];
				setModel(model);
			});

		Client.getVisualizations(modelId)
			.then((response) => response.json())
			.then((body) => {
				setIsLoadingImage(false);
				if (body.length === 0) {
					return;
				}

				const binaryString = atob(body[0]);

				// Convert the binary string to a Uint8Array
				const uint8Array = new Uint8Array(binaryString.length);
				for (let i = 0; i < binaryString.length; i++) {
					uint8Array[i] = binaryString.charCodeAt(i);
				}

				// Create a blob from the Uint8Array
				const blob = new Blob([uint8Array], { type: "image/png" });

				// Create a URL for the blob
				setImageUrl(URL.createObjectURL(blob));
			});
	}, [model, modelId]);

	return (
		<div className="model-info-parent">
			<div className="model-info-data">
				{!model && (
					<div className="model-info-loader-container">
						<Loader />
					</div>
				)
				}
				{model && (
					<>
						<div>
							<MetricBox metricName="Model Name" metricValue={model.ModelName}></MetricBox>
							<MetricBox metricName="Model Type" metricValue={model.ModelType}></MetricBox>
							{renderJson(jsonParser(model.ModelMetrics))}
							<MetricBox metricName="Inputs" metricValue={model.InputColumns[0]}></MetricBox>
							<MetricBox metricName="Output" metricValue={model.OutputColumn}></MetricBox>
							<MetricBox metricName="Status" metricValue={model.Status}></MetricBox>
							<MetricBox metricName="Date Created" metricValue={dateFormatter(model.DateCreated)}></MetricBox>
						</div>

						<div className="metric-box metric-predictor spread-card">
							<Predictor model={model} />
						</div>
					</>
				)}
			</div>

			<div className="model-info-image">
				{isLoadingImage ? (
					<div className="model-info-loader-container">
						<Loader />
					</div>
				) : (
					imageUrl ? (
						<div className="metric-image spread-card">
							<div className="metric-label">Feature Importance</div>
							<div className="metric-value"><img src={imageUrl} alt="model-graph" /></div>
						</div>
					) : (
						<div className="model-info-no-image">no graph</div>
						
					)
				)}
			</div>
		</div>
	);
};

export default ModelInfo;
