import './index.css';

export interface LoaderProps {
    isResizeable?: boolean;
}


const Loader = (props: LoaderProps) => {
    const { isResizeable } = props;

    const loaderClassName = `spinner${isResizeable ? " spinner-dynamic" : " spinner-fixed"}`

    return (
        <div className={loaderClassName}></div>
    );
}

export default Loader;