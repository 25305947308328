import "./index.css";
import Login from "../../components/Login";

export interface LoginPageProps {
    onAuth: Function;
}

const LoginPage = (props: LoginPageProps) => {
    const { onAuth } = props;

    return (
        <div id="login-container">
            <h2 className="login-tagline">The one-stop playground for all your ML experiments</h2>
            <Login onAuth={onAuth} />
        </div>
    );
};

export default LoginPage;