enum EndpointKeys {
    API = 'apiEndpoint',
    METRICS = "metricsEndpoint"
}

export class Endpoints {
    private static config: Map<EndpointKeys, string | { [key: string]: string }>

    static async fetchEndpointConfig() {
        await fetch('/endpoints/config.json')
            .then(response => (response.json()))
            .then(body => {
                Endpoints.config = new Map(
                    Object.entries(body).map(entry => [
                        entry[0] as unknown as EndpointKeys,
                        entry[1] as unknown as string,
                    ])
                );
            });
    }

    static apiEndpoint(): string {
        return Endpoints.config.get(EndpointKeys.API)! as string;
    }

    static metricsEndpoint(): string {
        return Endpoints.config.get(EndpointKeys.METRICS)! as string;
    }
}