import { useEffect, useState } from "react";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Metrics } from "../webcore/metrics/client";
import DocsHome from "./Home";
import "./index.css";
import closeIcon from '../assets/icons/close.svg';
import ModelsPage from "./Models";
import ClassificationPage from "./Models/Classification";
import ClusteringPage from "./Models/Clustering";
import RegressionPage from "./Models/Regression";
import GettingStartedPage from "./GettingStarted";
import CreateSheetDocs from "./GettingStarted/CreateSheets";
import UseSheetDocs from "./GettingStarted/UseSheets";
import PredictorsDocs from "./GettingStarted/UsePredictors";
import GptPage from "./Gpt";

export interface PageLink {
    label: string;
    path: string;
    children: PageLink[];
}

const modelPages: PageLink[] = [
    {
        label: "Regression",
        path: "/regression",
        children: []
    },
    {
        label: "Classification",
        path: "/classification",
        children: []
    },
    {
        label: "Clustering",
        path: "/clustering",
        children: []
    }
];



const pages: PageLink[] = [

    {
        label: "Getting Started",
        path: "/getting-started",
        children: [
            {
                label: "Create/Delete Sheets",
                path: "/create",
                children: []
            },
            {
                label: "Using Sheets",
                path: "/sheet",
                children: []
            },
            {
                label: "Using Predictors",
                path: "/predictors",
                children: []
            }
        ]
    },
    {
        label: "Models",
        path: "/models",
        children: modelPages
    },
    {
        label: "GPT",
        path: '/gpt',
        children: []
    }
]

interface DocsNavLinksProps {
    links: PageLink[];
    root: string;
    depth: number;
}

const DocsNavLinks = (props: DocsNavLinksProps) => {
    const url = useLocation().pathname;
    const { links, root, depth } = props;

    return (
        <div className={`docs-nav-list-${depth}`}>
            {links.map(link => {
                const fullPath = `${root}${link.path}`;
                const groupClassName = `docs-name-group docs-nav-group-${depth}${url === fullPath ? " docs-nav-group-selected" : ""}`;
                const linkClassName = `docs-nav-item docs-nav-item-${depth}${url === fullPath ? " docs-nav-selected" : ""}`;

                return (
                    <div key={link.path} className={groupClassName}>
                        <div className={linkClassName}>
                            <Link to={fullPath}>{link.label}</Link>
                        </div>
                        {link.children && <DocsNavLinks links={link.children} root={fullPath} depth={depth + 1} />}
                    </div>
                );
            }
            )}
        </div>
    )
}

const DocsNavigator = () => {
    const [isOpen, setIsOpen] = useState<boolean>(true);

    return (
        <div className="docs-nav">
            {isOpen ? (
                <div className="docs-nav-open">
                    <div className="docs-nav-content docs-nav-open-content">
                        <div className="docs-nav-header">
                            <div className="docs-nav-header-title">
                                <Link to={'/docs'}>SpreadML Docs</Link>
                            </div>
                            <div onClick={() => { setIsOpen(false) }}>
                                <img className="docs-nav-header-icon" src={closeIcon} alt="close" />
                            </div>
                        </div>
                        <DocsNavLinks links={pages} root={'/docs'} depth={0} />
                    </div>
                </div>
            ) : (
                <div className="docs-nav-closed">
                    <div className="docs-nav-content docs-nav-closed-content">
                        <div onClick={() => { setIsOpen(true) }}>Open</div>
                    </div>
                </div>
            )}
        </div>




    )
}

const DocsLayout = () => {
    return (
        <div className="docs-layout">
            <DocsNavigator />
            <div className="docs-container">
                <div className="docs-formatted-wrapper">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}


const DocsPage = () => {

    useEffect(() => {
        Metrics.logEvent("PageHitDocs");
    }, []);

    return (
        <div id="docs-container">
            <Routes>
                <Route path="/" element={<DocsLayout />}>
                    <Route index element={<DocsHome />} />
                    <Route path="getting-started/*">
                        <Route index element={<GettingStartedPage />} />
                        <Route path="create" element={<CreateSheetDocs />} />
                        <Route path="sheet" element={<UseSheetDocs />} />
                        <Route path="predictors" element={<PredictorsDocs />} />
                    </Route>
                    <Route path="models/*">
                        <Route index element={<ModelsPage />} />
                        <Route path="regression" element={<RegressionPage />} />
                        <Route path="classification" element={<ClassificationPage />} />
                        <Route path="clustering" element={<ClusteringPage />} />
                    </Route>
                    <Route path="gpt" element={<GptPage />} />
                </Route>
            </Routes>
        </div>
    );
};

export default DocsPage;