const UseSheetDocs = () => {

    return (
        <div className="docs-container-no-example">
            <h1>How to use Sheets</h1>

            <p>Sheets are the essence of SpreadML. The Sheet will be where you can do all the important operations you would like to do with your data. If you have used Microsoft Excel, Google Sheets, or any other spreadsheet software then navigating the Sheet will be second nature.</p>
            <p>To get started working on your Sheet, press the Open button on the Sheet of your choice on the Home Page.  Once on the Sheet, you will see many options of things you can do:</p>
            <p><u>Navigate the Sheet:</u> You can both use arrow keys or Shift and Tab buttons on your keyboard. </p>
            <p><u>Change the type of a column:</u> You can click on text at the top cell of a column and change it another type such as number, category, and date.</p>
            <p><u>Open the Predict menu:</u> Click the Predict button to the toggle the Predictor menu.</p>

        </div>

    );


};

export default UseSheetDocs;