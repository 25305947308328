import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Client } from "../../webcore/client/client";
import { Model, Sheet, User } from "../../webcore/client/types";
import FileUploader from "../FileUploader";
import './index.css';
import spreadsheetIcon from '../../assets/icons/spreadsheet.svg';
import dotsIcon from '../../assets/icons/dots.svg';
import Modal from "../common/Modal";
import { Metrics } from "../../webcore/metrics/client";
import Table, { ColumnDefinition } from "../common/Table";
import Loader from "../common/Loader";

export interface SheetCardProps {
    sheetName: string,
    sheetId: string,
    dateCreated: string,
    isLast: boolean,
    onDelete: Function,
}

export interface OptionsButtonProps {
    onDelete: Function,
    sheetId: string
}

export const OptionsButton = (props: OptionsButtonProps) => {
    const { onDelete, sheetId } = props;

    const [showDropdown, setShowDropdown] = useState(false);

    const handleDivClick = () => {
        setShowDropdown(!showDropdown);
    }

    const handleDivBlur = () => {
        setShowDropdown(false);
    }

    const handleDelete = () => {
        setShowDropdown(false);
        onDelete(sheetId);
    }

    return (
        <div tabIndex={0} className="options-button" onBlur={handleDivBlur} >
            <img className="dots-icon" src={dotsIcon} onClick={handleDivClick} alt="options" />
            {showDropdown && (
                <ul className="options-dropdown">
                    <li className="delete-sheet-option" onClick={handleDelete}>Delete</li>
                </ul>
            )}
        </div>
    );
}

export const SheetCard = (props: SheetCardProps) => {
    const { sheetName, sheetId, dateCreated, isLast, onDelete } = props;
    const sheetUri = `/sheet/${sheetId}`;

    console.log(dateCreated);
    const formattedDateCreated = new Date(dateCreated).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    const sheetRowClassName = `sheets-list-row sheet-item ${isLast ? "last-sheet-item" : ""}`

    return (
        <div className={sheetRowClassName}>
            <div className="sheets-list-col sheet-name-col">
                <img className="spreadsheet-icon" src={spreadsheetIcon} alt="spreadsheet" />
                {sheetName}
            </div>
            <div className="sheets-list-col date-created-col">
                {formattedDateCreated}
            </div>
            <div className="sheets-list-col sheet-link-col">
                <Link to={sheetUri} >
                    <div className={`sheet-link`}>
                        Open
                    </div>

                </Link>
            </div>
            <div className="sheets-list-col options-col">
                <OptionsButton onDelete={onDelete} sheetId={sheetId} />
            </div>
        </div>
    );

}

const sheetColumnDefinitions: ColumnDefinition[] = [
    {
        label: "Sheet Name",
        id: "SheetName",
        sorter: ((a: Sheet, b: Sheet) => a.SheetName.toLowerCase().localeCompare(b.SheetName.toLowerCase()))
    },
    {
        label: "Date Created",
        id: "DateCreated",
        sorter: ((a: Sheet, b: Sheet) => (new Date(a.DateCreated).getTime() - new Date(b.DateCreated).getTime()))
    },
    {
        label: "",
        id: "link"
    }
];

const modelColumnDefinitions: ColumnDefinition[] = [
    {
        label: "Model Name",
        id: "ModelName",
        sorter: ((a: Model, b: Model) => a.ModelName.toLowerCase().localeCompare(b.ModelName.toLowerCase()))
    },
    {
        label: "Model Type",
        id: "ModelType",
        sorter: ((a: Model, b: Model) => a.ModelType.toLowerCase().localeCompare(b.ModelType.toLowerCase()))
    },
    {
        label: "Status",
        id: "Status",
        sorter: ((a: Model, b: Model) => a.Status.toLowerCase().localeCompare(b.Status.toLowerCase()))
    },
    {
        label: "Inputs",
        id: "InputColumns",
    },
    {
        label: "Output",
        id: "OutputColumn",
    },
    {
        label: "Date Created",
        id: "DateCreated",
        sorter: ((a: Sheet, b: Sheet) => (new Date(a.DateCreated).getTime() - new Date(b.DateCreated).getTime()))
    }
];

const SheetsTable = () => {
    const [message, setMessage] = useState<string>("");
    const [sheets, setSheets] = useState<Sheet[] | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDelete = (sheet: Sheet) => {
        setIsLoading(true);
        Metrics.logEvent("DeleteSheet");
        Client.deleteSheet(sheet.SheetId)
            .then(response => {
                if (response.ok) {
                    getSheets();
                } else {
                    setMessage("Problem deleting sheet.");
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        getSheets();
    }, []);

    const handleModalOpen = () => {
        setIsShowModal(true);
    };

    const handleModalClose = () => {
        setIsShowModal(false);
    };

    const getSheets = () => {
        setIsShowModal(false);
        setIsLoading(true);

        Client.getSheets()
            .then(response => (response.json()))
            .then(body => {
                setSheets(body["sheets"]);
                setMessage("");
                setIsLoading(false);
            })
            .catch(e => {
                setMessage("Problem loading sheets.");
                setIsLoading(false);
            });
    }

    return (
        <div>
            <div>
                {isShowModal && (
                    <Modal
                        onClose={handleModalClose}>
                        <FileUploader onUpload={getSheets} />
                    </Modal>
                )}

            </div>
            {message && (<div>{message}</div>)}

            <Table
                loading={isLoading}
                title={"Sheets"}
                items={sheets ? sheets.map((sheet) => (
                    {
                        ...sheet,
                        DateCreated: new Date(sheet.DateCreated).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }),
                        link: <div className="sheets-list-col sheet-link-col">
                            <Link to={`/sheet/${sheet.SheetId}`} >
                                <div className={`sheet-link`}>
                                    Open
                                </div>

                            </Link>
                        </div>
                    }
                )) : []}
                columns={sheetColumnDefinitions}
                actions={[
                    {
                        label: "Refresh",
                        onClick: getSheets,
                    },
                    {
                        label: "Delete",
                        onClick: handleDelete,
                        hasArgs: true,
                        limitToOne: true
                    },
                    {
                        label: "Create",
                        onClick: handleModalOpen,
                    }
                ]}
            />
        </div>
    );
};

const ModelsTable = () => {
    const [message, setMessage] = useState<string>("");
    const [models, setModels] = useState<Model[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDelete = (model: Model) => {
        setIsLoading(true);
        Metrics.logEvent("DeleteModel");
        Client.deleteModel(model.ModelId)
            .then(response => {
                if (response.ok) {
                    getModels();
                } else {
                    setMessage("Problem deleting sheet.");
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        getModels();
    }, []);

    const getModels = () => {
        setIsLoading(true);

        Client.getModels()
            .then(response => (response.json()))
            .then(body => {
                setModels(body["Models"]);
                setMessage("");
                setIsLoading(false);
            })
            .catch(e => {
                setMessage("Problem loading models.");
                setIsLoading(false);
            });
    }

    return (
        <div className="modelslist-container">
            {message && (<div>{message}</div>)}
            <Table
                loading={isLoading}
                title={"Models"}
                items={models ? models : []}
                columns={modelColumnDefinitions}
                actions={[
                    {
                        label: "Refresh",
                        onClick: getModels,
                    },
                    {
                        label: "Delete",
                        onClick: handleDelete,
                        hasArgs: true,
                        limitToOne: true
                    }
                ]}
            />
        </div>
    );
}


const HomeApp = () => {
    const [showSheets, setShowSheets] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        Client.getUser()
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(body => {
                setUser(body);
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className="sheets-list-container">
            <h2 className="sheets-list-header">
                {isLoading ? (
                    <Loader />
                ) : (
                    user ? `Welcome back, ${user.FirstName}` : "Welcome back"
                )}
            </h2>

            <div className="home-app-toggle-wrapper">
                <div
                    className={`home-app-toggle-button ${showSheets ? "home-app-toggle-selected" : ""}`}
                    onClick={() => { setShowSheets(true) }}
                >
                    Sheets
                </div>
                <div
                    className={`home-app-toggle-button ${!showSheets ? "home-app-toggle-selected" : ""}`}
                    onClick={() => { setShowSheets(false) }}
                >
                    Models
                </div>
                <div
                    className="home-app-toggle-button"
                >

                </div>
                <div
                    className="home-app-toggle-button"
                >

                </div>
                <div
                    className="home-app-toggle-button"
                >

                </div>
            </div>


            {showSheets ? <SheetsTable /> : <ModelsTable />}
        </div>
    );
}

export default HomeApp;